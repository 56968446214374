import { useQuery } from "@apollo/client";
import useSession from "../helpers/useSessionState";
import { Button, Drawer } from "@mui/material";
import { useState } from "react";

import { Link, useNavigate } from "react-router-dom";
import Layout from "../components/Layout";
import CreateSite from "../components/forms/SiteCreate";
// import { SitesQuery, useSitesQuery } from "../apiClient/generated/graphql";

// type Site = NonNullable<NonNullable<SitesQuery["sites"]>[number]>

const Sites = ()=>{
    const [state, setState] = useState({
        drawerOpen: false
    })

    const navigate = useNavigate()
    // const { loading, error, data, refetch } = useSitesQuery()

    const [sessionState, setSession] = useSession()
    // const setDrawer = (value: boolean)=>{
    //     refetch()
    //     setState({
    //         ...state,
    //         drawerOpen: value
    //     })
    // }
    
    // const handleSelectSite = (site: Site)=>{
    //     setSession({
    //         ...Object(sessionState),
    //         currentSiteId: site.id
    //     })
    //     navigate("/")
    // }

    return <Layout page="Sites">
        {/* {loading ? (
            <>
                LOADING...
            </>
        ):error?(
            <>{error.message}</>
        ):(
            <> */}
                {/* {sessionState?.isSuper && sessionState?.currentPartnerId != null && (
                    <div>
                        <Button variant="contained" onClick={setDrawer.bind(null, true)}>Create Site</Button>
                        <Drawer
                            anchor='right'
                            open={state.drawerOpen}
                            onClose={setDrawer.bind(null, false)}
                        >
                            <CreateSite onComplete={setDrawer.bind(null, false)}/>
                        </Drawer>
                    </div>
                )} */}
                
                {/* {data?.sites?.map((site, i: Number)=>(
                    <div key={i.toString()}>
                        <Link to="#" onClick={handleSelectSite.bind(null, site as Site)} key={i.toString()}>
                            {site?.name}
                        </Link>
                    </div>
                ))} */}
            {/* </> */}
        {/* )} */}
    </Layout>
}

export default Sites