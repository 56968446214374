import { useMutation, useQuery } from "@apollo/client";
import useSession from "../helpers/useSessionState";
import { Button, FormControlLabel, Switch } from "@mui/material";
import { useState } from "react";

import { useParams } from "react-router-dom";
import Layout from "../components/Layout";
import UpdatePractice from "../components/forms/PracticeUpdate";
import { usePracticeQuery, useResetPracticeMutation, useUpdatePracticeMutation } from "../apiClient/generated/graphql";

const Practice = () => {
  const [state, setState] = useState({
    drawerOpen: false,
    isEdit: false,
    isReset: false,
  });

  const params = useParams();

  const [updatePractice] = useUpdatePracticeMutation();


  const { data, loading, error, refetch } = usePracticeQuery({
    variables: {
      id: params.id,
    },
  });
  

  const [
    resetPractice,
  ] = useResetPracticeMutation();

  const handleResetPractice = () => {
    resetPractice({
      variables: {
        practice: {
          id: data?.practice?.id,
        },
      },
    }).then(() => {
      setState({
        ...state,
        isReset: true,
      });
      setTimeout(() => {
        setState({
          ...state,
          isReset: false,
        });
      }, 15000);
    });
  };

  const handleActivateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (data?.practice?.id) {
      updatePractice({
        variables: {
          practice: {
            id: data.practice.id,
            isActive: e.target.checked,
          },
        },
      }).then(() => {
        refetch();
      });
    }
  };

  const handleEditOn = () => {
    setState({
      ...state,
      isEdit: true,
    });
  };

  const handleEditOff = () => {
    setState({
      ...state,
      isEdit: false,
    });
  };

  return (
    <Layout page="Practices">
      {loading ? (
        <>LOADING...</>
      ) : error ? (
        <>{error.message}</>
      ) : (
        data?.practice?.id && (
          <>
            <div>
              <Button
                onClick={handleEditOn}
                style={{ float: "right", margin: "0 10px" }}
                fullWidth={false}
                variant="contained"
              >
                Edit
              </Button>
              <Button
                onClick={handleResetPractice}
                style={{ float: "right", margin: "0 10px" }}
                fullWidth={false}
                variant="contained"
                color="error"
              >
                Reset
              </Button>
            </div>

            {state.isEdit && data.practice.id ? (
              <UpdatePractice
                practiceId={data.practice.id}
                onComplete={handleEditOff}
              />
            ) : (
              <>
                {state.isReset && (
                  <div style={{ color: "red" }}>Practice has been reset.</div>
                )}

                <div style={{ textAlign: "left" }}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={!!data?.practice?.isActive}
                        onChange={handleActivateChange}
                      />
                    }
                    label="Is Active"
                  />
                  <br />
                  Id: {data.practice.id}
                  <br />
                  Name: {data.practice.name}
                  <br />
                  Label: {data.practice.label}
                  <br />
                  Email: {data.practice.email}
                  <br />
                  Go Live Date: {data.practice.goLiveDate}
                  <br />
                  Contact: {data.practice.contact}
                  <br />
                  Color Code: {data.practice.colorCode}
                  <br />
                  Facebook Url: {data.practice.facebookUrl}
                  <br />
                  Language Id: {data.practice.languageId}
                  <br />
                  Locale Id: {data.practice.localeId}
                  <br />
                  Currency Id: {data.practice.currencyId}
                  <br />
                  TimeZone Id: {data.practice.timezoneId}
                  <br />
                  Migrated From: {data.practice.migratedFrom}
                  <br />
                  Website Url: {data.practice.websiteUrl}
                  <br />
                  Installation: {data.practice.installation}
                  <br />
                  Site Id: {data.practice.siteId}
                  <br />
                  Server Id: {data.practice.serverId}
                  <br />
                  Integration: {data.practice.integration}
                  <br />
                  Integration Id: {data.practice.integrationId}
                  <br />
                  Is Active: {data.practice.isActive}
                  <br />
                  Is Deleted: {data.practice.isDeleted}
                  <br />
                  Private Key: {data.practice.privateKey}
                  <br />
                  Created At: {data.practice.createdAt}
                  <br />
                  Updated At: {data.practice.updatedAt}
                  <br />
                </div>
              </>
            )}
          </>
        )
      )}
    </Layout>
  );
};

export default Practice;
