import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  gql,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { loadErrorMessages, loadDevMessages } from "@apollo/client/dev";
import config from "../../config";

loadDevMessages();
loadErrorMessages();

let params = new URLSearchParams(document.location.search);

const sessionString = localStorage.getItem("session");
let state = params.get("state");
if (sessionString) {
  const session = JSON.parse(sessionString);
  if (session?.currentLocationId) state = session.currentLocationId;
}

const uri = config.env.isDev
  ? "http://localhost:8080/graphql"
  : `https://${state}.datahubvet.com/graphql`;

const httpLink = createHttpLink({
  uri,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const sessionString = localStorage.getItem("session");
  let session: { token?: string; currentPartnerId?: string } = {};
  try {
    if (sessionString) {
      session = JSON.parse(sessionString);
    }
  } catch (error) {
    console.log(error);
  }

  const token = session.token;
  let optional = {};
  if (token) {
    optional = {
      authorization: token ? `Bearer ${token}` : "",
      "X-Partner-Id": session.currentPartnerId,
    };
  }

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      ...optional,
    },
  };
});

const apiClient = {
  connection: new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  }),
  authenticate: (token: string) => {
    return apiClient.connection.mutate({
      mutation: gql`
        mutation ($token: String!) {
          authenticate(token: $token) {
            firstName
            lastName
            email
            token
            isSuper
          }
        }
      `,
      variables: {
        token,
      },
    });
  },
};

export default apiClient;
