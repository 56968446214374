import { useMutation, useQuery } from "@apollo/client";
import { Box, Button, FormControl, FormControlLabel, Input, InputLabel, Switch, Typography } from "@mui/material";
import { useForm } from "react-hook-form"

import { useEffect, useState } from "react";
import { usePartnerQuery, usePracticesQuery, useUpdatePartnerMutation } from "../../apiClient/generated/graphql";

interface PartnerFormInputs {
    name: string
    singleClientOwnership: Boolean
}

interface PartnerUpdateProps {
    partnerId: string,
    onComplete?: Function
}
const UpdatePartner = (props: PartnerUpdateProps)=>{
    const {
        register,
        handleSubmit,
        // formState: { errors },
        reset,
        setValue
    } = useForm<PartnerFormInputs>()
        
    const [updatePartner, { loading, error }] = useUpdatePartnerMutation()
    const partnerQuery = usePartnerQuery({
        variables: {
            id: props.partnerId
        }
    });
    
    const partner = partnerQuery.data?.partner

    useEffect(()=>{
        
        reset({
            name: partner?.name ?? '',
            singleClientOwnership: partner?.singleClientOwnership ?? false,
        })
    }, [partnerQuery.data?.partner])
    

    const practicesQuery = usePracticesQuery();



    const handleUpdatePartner = (partnerParams: any)=>{
        if(partner?.id){
            const variables = {
                partner: {
                    name: partnerParams.name,
                    singleClientOwnership: partnerParams.singleClientOwnership,
                    id: partner.id
                }
            }

            console.log(variables)
            updatePartner({
                variables
            }).then(()=>{
                if(props.onComplete) props.onComplete()
            }).catch((error)=>{
                console.log(error)
            })
        }
    }
   
    const handleActiveChange = (e: React.ChangeEvent<HTMLInputElement>)=>{
        setValue("singleClientOwnership", !!e.target.checked)
    }

    return (
        <Box
            sx={{p: 2}}
            style={{minWidth: 400}}
        >
            {!partnerQuery.loading && !practicesQuery.loading && (

                <form onSubmit={handleSubmit(handleUpdatePartner)}>
                    {/* register your input into the hook by invoking the "register" function */}
                    {loading ? (<>
                        Loading...
                    </>):(
                        <>
                            {error && error?.message}
                            
                            {error && error?.message}
                            <FormControl>
                                <Typography component="h4">
                                    New Partner
                                </Typography>
                            </FormControl>
                            <br />
                            <br />
                            <FormControl>
                                <InputLabel htmlFor="name">Partner Name</InputLabel>
                                <Input id="name" defaultValue="" {...register("name")} required={true} />
                                <FormControlLabel control={<Switch onChange={handleActiveChange} defaultChecked={!!partnerQuery.data?.partner?.singleClientOwnership} />} label='Single Client Ownership'  /> 
                            </FormControl>

                            {/* include validation with required or other standard HTML validation rules */}
                            {/* errors will return when field validation fails  */}
                            <br />
                            <Button type="submit" variant="contained">Update</Button>
                        </>
                    )}
                </form>
            )}
        </Box>
    )
}

export default UpdatePartner