import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: any; output: any; }
  Int32: { input: any; output: any; }
  Int64: { input: any; output: any; }
  UUID: { input: any; output: any; }
};

export type AppointmentEmployeeSync = {
  __typename?: 'AppointmentEmployeeSync';
  appointmentId?: Maybe<Scalars['UUID']['output']>;
  covetrusId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  employeeId?: Maybe<Scalars['UUID']['output']>;
  id?: Maybe<Scalars['UUID']['output']>;
  integrationId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type AppointmentEmployeesSyncInput = {
  appointmentId?: InputMaybe<Scalars['UUID']['input']>;
  covetrusId?: InputMaybe<Scalars['String']['input']>;
  employeeId?: InputMaybe<Scalars['UUID']['input']>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  integrationId?: InputMaybe<Scalars['String']['input']>;
};

export type AppointmentStatus = {
  __typename?: 'AppointmentStatus';
  covetrusId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  entity?: Maybe<Scalars['String']['output']>;
  iconName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  integration?: Maybe<Scalars['String']['output']>;
  integrationId?: Maybe<Scalars['String']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  partition?: Maybe<Scalars['String']['output']>;
  pimsCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  pimsDeletedAt?: Maybe<Scalars['DateTime']['output']>;
  pimsId?: Maybe<Scalars['String']['output']>;
  pimsUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  practiceId?: Maybe<Scalars['String']['output']>;
  sequence?: Maybe<Scalars['Int32']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type AppointmentStatusSync = {
  __typename?: 'AppointmentStatusSync';
  id?: Maybe<Scalars['UUID']['output']>;
  integrationId?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type AppointmentStatusSyncInput = {
  backgroundColor?: InputMaybe<Scalars['String']['input']>;
  category?: InputMaybe<Scalars['String']['input']>;
  covetrusId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  entity?: InputMaybe<Scalars['String']['input']>;
  foregroundColor?: InputMaybe<Scalars['String']['input']>;
  iconName?: InputMaybe<Scalars['String']['input']>;
  integration?: InputMaybe<Scalars['String']['input']>;
  integrationId?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  partition?: InputMaybe<Scalars['String']['input']>;
  pimsCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  pimsDeletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  pimsId?: InputMaybe<Scalars['String']['input']>;
  pimsUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  sequence?: InputMaybe<Scalars['Int32']['input']>;
};

export type AppointmentSync = {
  __typename?: 'AppointmentSync';
  id?: Maybe<Scalars['UUID']['output']>;
  integration?: Maybe<Scalars['String']['output']>;
  integrationId?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type AppointmentSyncInput = {
  appointmentStatus?: InputMaybe<AppointmentsAppointmentStatus>;
  appointmentType?: InputMaybe<AppointmentsAppointmentType>;
  client?: InputMaybe<AppointmentsClient>;
  covetrusId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  endsAt?: InputMaybe<Scalars['DateTime']['input']>;
  entity?: InputMaybe<Scalars['String']['input']>;
  /** Integration name example: Datahub.Avimark */
  integration: Scalars['String']['input'];
  /** Id used to keep track example: 1234 */
  integrationId: Scalars['String']['input'];
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  isIntake?: InputMaybe<Scalars['Boolean']['input']>;
  partition?: InputMaybe<Scalars['String']['input']>;
  patient?: InputMaybe<PatientSyncInput>;
  pimsAppointmentStatusId?: InputMaybe<Scalars['String']['input']>;
  pimsAppointmentTypeId?: InputMaybe<Scalars['String']['input']>;
  pimsClientId?: InputMaybe<Scalars['String']['input']>;
  pimsCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  pimsDeletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  pimsId?: InputMaybe<Scalars['String']['input']>;
  pimsPatientId?: InputMaybe<Scalars['String']['input']>;
  pimsResourceId?: InputMaybe<Scalars['String']['input']>;
  pimsUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
  resource?: InputMaybe<Resource>;
  startsAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type AppointmentType = {
  __typename?: 'AppointmentType';
  backgroundColor?: Maybe<Scalars['String']['output']>;
  color?: Maybe<Scalars['String']['output']>;
  covetrusId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  defaultDuration?: Maybe<Scalars['Int32']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  entity?: Maybe<Scalars['String']['output']>;
  excludeFromAutomaticSmsSend?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  integration?: Maybe<Scalars['String']['output']>;
  integrationId?: Maybe<Scalars['String']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isBoarding?: Maybe<Scalars['Boolean']['output']>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  partition?: Maybe<Scalars['String']['output']>;
  pimsCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  pimsDeletedAt?: Maybe<Scalars['DateTime']['output']>;
  pimsId?: Maybe<Scalars['String']['output']>;
  pimsUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  practiceId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type AppointmentTypeSync = {
  __typename?: 'AppointmentTypeSync';
  id?: Maybe<Scalars['UUID']['output']>;
  integrationId?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type AppointmentTypeSyncInput = {
  backgroundColor?: InputMaybe<Scalars['String']['input']>;
  color?: InputMaybe<Scalars['String']['input']>;
  covetrusId?: InputMaybe<Scalars['String']['input']>;
  defaultDuration?: InputMaybe<Scalars['Int32']['input']>;
  entity?: InputMaybe<Scalars['String']['input']>;
  excludeFromAutomaticSmsSend?: InputMaybe<Scalars['Boolean']['input']>;
  integration?: InputMaybe<Scalars['String']['input']>;
  integrationId?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isBoarding?: InputMaybe<Scalars['Boolean']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  partition?: InputMaybe<Scalars['String']['input']>;
  pimsCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  pimsDeletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  pimsId?: InputMaybe<Scalars['String']['input']>;
  pimsUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type AppointmentsAppointmentStatus = {
  integrationId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type AppointmentsAppointmentType = {
  integrationId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type AppointmentsClient = {
  firstName?: InputMaybe<Scalars['String']['input']>;
  integrationId?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
};

export type Blockoff = {
  __typename?: 'Blockoff';
  covetrusId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  endsAt?: Maybe<Scalars['DateTime']['output']>;
  entity?: Maybe<Scalars['String']['output']>;
  exceptionBlockoffId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['UUID']['output']>;
  integration?: Maybe<Scalars['String']['output']>;
  integrationId?: Maybe<Scalars['String']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  isException?: Maybe<Scalars['Boolean']['output']>;
  isRepeating?: Maybe<Scalars['Boolean']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  partition?: Maybe<Scalars['String']['output']>;
  pimsCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  pimsDeletedAt?: Maybe<Scalars['DateTime']['output']>;
  pimsId?: Maybe<Scalars['String']['output']>;
  pimsResourceId?: Maybe<Scalars['String']['output']>;
  pimsUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  practiceId?: Maybe<Scalars['UUID']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
  resourceId?: Maybe<Scalars['UUID']['output']>;
  rrule?: Maybe<Scalars['String']['output']>;
  startsAt?: Maybe<Scalars['DateTime']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type BlockoffSync = {
  __typename?: 'BlockoffSync';
  id?: Maybe<Scalars['UUID']['output']>;
  integrationId?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type BlockoffSyncInput = {
  covetrusId?: InputMaybe<Scalars['String']['input']>;
  endsAt?: InputMaybe<Scalars['DateTime']['input']>;
  entity?: InputMaybe<Scalars['String']['input']>;
  integration?: InputMaybe<Scalars['String']['input']>;
  integrationId?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  isException?: InputMaybe<Scalars['Boolean']['input']>;
  isRepeating?: InputMaybe<Scalars['Boolean']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  partition?: InputMaybe<Scalars['String']['input']>;
  pimsCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  pimsDeletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  pimsId?: InputMaybe<Scalars['String']['input']>;
  pimsResourceId?: InputMaybe<Scalars['String']['input']>;
  pimsType?: InputMaybe<Scalars['String']['input']>;
  pimsUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
  resource?: InputMaybe<ResourceSyncInput>;
  rrule?: InputMaybe<Scalars['String']['input']>;
  startsAt?: InputMaybe<Scalars['DateTime']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type Breed = {
  __typename?: 'Breed';
  covetrusId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  entity?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['UUID']['output']>;
  integration?: Maybe<Scalars['String']['output']>;
  integrationId?: Maybe<Scalars['String']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  partition?: Maybe<Scalars['String']['output']>;
  pimsCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  pimsDeletedAt?: Maybe<Scalars['DateTime']['output']>;
  pimsId?: Maybe<Scalars['String']['output']>;
  pimsSpeciesId?: Maybe<Scalars['String']['output']>;
  pimsUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  practiceId?: Maybe<Scalars['UUID']['output']>;
  speciesId?: Maybe<Scalars['UUID']['output']>;
  speciesIntegrationId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type BreedSync = {
  __typename?: 'BreedSync';
  id?: Maybe<Scalars['UUID']['output']>;
  integrationId?: Maybe<Scalars['String']['output']>;
  size?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type BreedSyncInput = {
  covetrusId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  deletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  entity?: InputMaybe<Scalars['String']['input']>;
  integration?: InputMaybe<Scalars['String']['input']>;
  integrationId?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  partition?: InputMaybe<Scalars['String']['input']>;
  pimsCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  pimsDeletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  pimsId?: InputMaybe<Scalars['String']['input']>;
  pimsSpeciesId?: InputMaybe<Scalars['String']['input']>;
  pimsUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  size?: InputMaybe<Scalars['String']['input']>;
  species?: InputMaybe<SpeciesSyncInput>;
  speciesIntegrationId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ClientEmailSyncInput = {
  email: Scalars['String']['input'];
  entity?: InputMaybe<Scalars['String']['input']>;
  integrationId: Scalars['String']['input'];
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  isPrimary?: InputMaybe<Scalars['Boolean']['input']>;
  pimsCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  pimsDeletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  pimsId?: InputMaybe<Scalars['String']['input']>;
  pimsUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  type: Scalars['String']['input'];
};

export type ClientPhoneNumberSync = {
  __typename?: 'ClientPhoneNumberSync';
  clientId?: Maybe<Scalars['UUID']['output']>;
  clientIntegrationId?: Maybe<Scalars['String']['output']>;
  covetrusId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  entity?: Maybe<Scalars['String']['output']>;
  integration?: Maybe<Scalars['String']['output']>;
  integrationId?: Maybe<Scalars['String']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  isPrimary?: Maybe<Scalars['Boolean']['output']>;
  partition?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  phoneType?: Maybe<Scalars['String']['output']>;
  pimsClientId?: Maybe<Scalars['String']['output']>;
  pimsCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  pimsDeletedAt?: Maybe<Scalars['DateTime']['output']>;
  pimsId?: Maybe<Scalars['String']['output']>;
  pimsUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ClientPhoneNumberSyncInput = {
  clientIntegrationId?: InputMaybe<Scalars['String']['input']>;
  covetrusId?: InputMaybe<Scalars['String']['input']>;
  entity?: InputMaybe<Scalars['String']['input']>;
  integration?: InputMaybe<Scalars['String']['input']>;
  integrationId?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  isPrimary?: InputMaybe<Scalars['Boolean']['input']>;
  isSmsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  partition?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  pimsClientId?: InputMaybe<Scalars['String']['input']>;
  pimsCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  pimsDeletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  pimsId?: InputMaybe<Scalars['String']['input']>;
  pimsUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type ClientSync = {
  __typename?: 'ClientSync';
  errors?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  id?: Maybe<Scalars['UUID']['output']>;
  integrationId?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type ClientSyncInput = {
  /** Address example: 1234 Main St */
  address?: InputMaybe<Scalars['String']['input']>;
  /** Address Extended example: Suite 100 */
  addressExtended?: InputMaybe<Scalars['String']['input']>;
  /** Balance example: -200 */
  balance?: InputMaybe<Scalars['Float']['input']>;
  /** City example: Anytown */
  city?: InputMaybe<Scalars['String']['input']>;
  /** Status example: Active */
  clientStatus?: InputMaybe<Scalars['String']['input']>;
  /** Codes example: 1234 */
  codes?: InputMaybe<Scalars['String']['input']>;
  /** Country example: United States */
  country?: InputMaybe<Scalars['String']['input']>;
  /** Covetrus Id: 1234 */
  covetrusId?: InputMaybe<Scalars['String']['input']>;
  /** Discount example: 10.0 */
  discount?: InputMaybe<Scalars['Float']['input']>;
  emails?: InputMaybe<Array<InputMaybe<ClientEmailSyncInput>>>;
  /** raw json value */
  entity?: InputMaybe<Scalars['String']['input']>;
  /** First name example: John */
  firstName?: InputMaybe<Scalars['String']['input']>;
  /** Integration name example: Datahub.Avimark */
  integration: Scalars['String']['input'];
  /** Id used to keep track example: 1234 */
  integrationId: Scalars['String']['input'];
  /** Is active Client example: true */
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  /** Is deleted Client example: false */
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  /** Last name example: Doe */
  lastName?: InputMaybe<Scalars['String']['input']>;
  /** Partition example: 1234 */
  partition?: InputMaybe<Scalars['String']['input']>;
  /** phone numbers */
  phoneNumbers?: InputMaybe<Array<InputMaybe<ClientPhoneNumberSyncInput>>>;
  /** PIMS created at example: 2021-01-01T00:00:00Z */
  pimsCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** PIMS deleted at example: 2021-01-01T00:00:00Z */
  pimsDeletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** PIMS id example: 1234 */
  pimsId?: InputMaybe<Scalars['String']['input']>;
  /** PIMS updated at example: 2021-01-01T00:00:00Z */
  pimsUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** Reference example: 1234 */
  reference?: InputMaybe<Scalars['String']['input']>;
  /** Spouse Name example: Jane Doe */
  spouseName?: InputMaybe<Scalars['String']['input']>;
  /** State example: TX */
  state?: InputMaybe<Scalars['String']['input']>;
  /** Unit example: Suite 100 */
  unit?: InputMaybe<Scalars['String']['input']>;
  /** Until example: 2021-01-01T00:00:00Z */
  until?: InputMaybe<Scalars['DateTime']['input']>;
  /** Zipcode example: 12345 */
  zipcode?: InputMaybe<Scalars['String']['input']>;
};

export type CreatePartnerHookInput = {
  headers?: InputMaybe<Array<InputMaybe<HeadersInput>>>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  parser?: InputMaybe<Scalars['String']['input']>;
  partnerId?: InputMaybe<Scalars['UUID']['input']>;
  practiceId: Scalars['UUID']['input'];
  sendAppointment?: InputMaybe<Scalars['Boolean']['input']>;
  sendAppointmentStatus?: InputMaybe<Scalars['Boolean']['input']>;
  sendAppointmentType?: InputMaybe<Scalars['Boolean']['input']>;
  sendBlockoff?: InputMaybe<Scalars['Boolean']['input']>;
  sendBreed?: InputMaybe<Scalars['Boolean']['input']>;
  sendClient?: InputMaybe<Scalars['Boolean']['input']>;
  sendClientPhoneNumber?: InputMaybe<Scalars['Boolean']['input']>;
  sendCurrency?: InputMaybe<Scalars['Boolean']['input']>;
  sendEmployee?: InputMaybe<Scalars['Boolean']['input']>;
  sendInvoice?: InputMaybe<Scalars['Boolean']['input']>;
  sendLanguage?: InputMaybe<Scalars['Boolean']['input']>;
  sendLocale?: InputMaybe<Scalars['Boolean']['input']>;
  sendOwnership?: InputMaybe<Scalars['Boolean']['input']>;
  sendPatient?: InputMaybe<Scalars['Boolean']['input']>;
  sendPatientReminder?: InputMaybe<Scalars['Boolean']['input']>;
  sendPractice?: InputMaybe<Scalars['Boolean']['input']>;
  sendResource?: InputMaybe<Scalars['Boolean']['input']>;
  sendSex?: InputMaybe<Scalars['Boolean']['input']>;
  sendSpecies?: InputMaybe<Scalars['Boolean']['input']>;
  sendTimezone?: InputMaybe<Scalars['Boolean']['input']>;
  url: Scalars['String']['input'];
};

export type CreatePracticeInput = {
  currencyId?: InputMaybe<Scalars['UUID']['input']>;
  goLiveDate?: InputMaybe<Scalars['DateTime']['input']>;
  installation?: InputMaybe<Scalars['String']['input']>;
  integration?: InputMaybe<Scalars['String']['input']>;
  languageId?: InputMaybe<Scalars['UUID']['input']>;
  localeId?: InputMaybe<Scalars['UUID']['input']>;
  name: Scalars['String']['input'];
  serverId?: InputMaybe<Scalars['UUID']['input']>;
  siteId?: InputMaybe<Scalars['Int64']['input']>;
  timezoneId?: InputMaybe<Scalars['UUID']['input']>;
  timezoneName?: InputMaybe<Scalars['String']['input']>;
};

export type CurrencySync = {
  __typename?: 'CurrencySync';
  covetrusId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  entity?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['UUID']['output']>;
  integration?: Maybe<Scalars['String']['output']>;
  integrationId?: Maybe<Scalars['String']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  partition?: Maybe<Scalars['String']['output']>;
  pimsCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  pimsDeletedAt?: Maybe<Scalars['DateTime']['output']>;
  pimsId?: Maybe<Scalars['String']['output']>;
  pimsUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CurrencySyncInput = {
  covetrusId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  deletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  entity?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  integration?: InputMaybe<Scalars['String']['input']>;
  integrationId?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  partition?: InputMaybe<Scalars['String']['input']>;
  pimsCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  pimsDeletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  pimsId?: InputMaybe<Scalars['String']['input']>;
  pimsUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type EmployeeSync = {
  __typename?: 'EmployeeSync';
  covetrusId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  entity?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  integration?: Maybe<Scalars['String']['output']>;
  integrationId?: Maybe<Scalars['String']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  partition?: Maybe<Scalars['String']['output']>;
  pimsCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  pimsDeletedAt?: Maybe<Scalars['DateTime']['output']>;
  pimsId?: Maybe<Scalars['String']['output']>;
  pimsUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type EmployeeSyncInput = {
  covetrusId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  deletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  entity?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  integration?: InputMaybe<Scalars['String']['input']>;
  integrationId?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  partition?: InputMaybe<Scalars['String']['input']>;
  pimsCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  pimsDeletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  pimsId?: InputMaybe<Scalars['String']['input']>;
  pimsUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type EntityType = {
  __typename?: 'EntityType';
  key?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type Header = {
  __typename?: 'Header';
  name?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type HeadersInput = {
  name: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type Hello = {
  __typename?: 'Hello';
  status?: Maybe<Scalars['String']['output']>;
};

export type InvoiceClientSyncInput = {
  firstName?: InputMaybe<Scalars['String']['input']>;
  integrationId?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
};

export type InvoiceSync = {
  __typename?: 'InvoiceSync';
  amountPaid?: Maybe<Scalars['Float']['output']>;
  clientId?: Maybe<Scalars['UUID']['output']>;
  covetrusId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  date?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  discountAmount?: Maybe<Scalars['Float']['output']>;
  discountType?: Maybe<Scalars['String']['output']>;
  employeeId?: Maybe<Scalars['UUID']['output']>;
  entity?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['UUID']['output']>;
  integration?: Maybe<Scalars['String']['output']>;
  integrationId?: Maybe<Scalars['String']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isComplete?: Maybe<Scalars['Boolean']['output']>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  isPaid?: Maybe<Scalars['Boolean']['output']>;
  number?: Maybe<Scalars['Int64']['output']>;
  partition?: Maybe<Scalars['String']['output']>;
  pimsClientId?: Maybe<Scalars['String']['output']>;
  pimsCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  pimsDeletedAt?: Maybe<Scalars['DateTime']['output']>;
  pimsId?: Maybe<Scalars['String']['output']>;
  pimsUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  practiceId?: Maybe<Scalars['UUID']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  taxAmount?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type InvoiceSyncInput = {
  amount?: InputMaybe<Scalars['Float']['input']>;
  amountPaid?: InputMaybe<Scalars['Float']['input']>;
  client?: InputMaybe<InvoiceClientSyncInput>;
  covetrusId?: InputMaybe<Scalars['String']['input']>;
  date?: InputMaybe<Scalars['DateTime']['input']>;
  discountAmount?: InputMaybe<Scalars['Float']['input']>;
  discountType?: InputMaybe<Scalars['String']['input']>;
  employee?: InputMaybe<EmployeeSyncInput>;
  entity?: InputMaybe<Scalars['String']['input']>;
  integration?: InputMaybe<Scalars['String']['input']>;
  integrationId?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isComplete?: InputMaybe<Scalars['Boolean']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  isPaid?: InputMaybe<Scalars['Boolean']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  number?: InputMaybe<Scalars['Int64']['input']>;
  partition?: InputMaybe<Scalars['String']['input']>;
  pimsClientId?: InputMaybe<Scalars['String']['input']>;
  pimsCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  pimsDeletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  pimsId?: InputMaybe<Scalars['String']['input']>;
  pimsResourceId?: InputMaybe<Scalars['String']['input']>;
  pimsUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
  resource?: InputMaybe<ResourceSyncInput>;
  status?: InputMaybe<SyncInvoiceStatus>;
  taxAmount?: InputMaybe<Scalars['Float']['input']>;
  total?: InputMaybe<Scalars['Float']['input']>;
  type?: InputMaybe<SyncInvoiceType>;
};

export type LanguageSync = {
  __typename?: 'LanguageSync';
  covetrusId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  entity?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['UUID']['output']>;
  integration?: Maybe<Scalars['String']['output']>;
  integrationId?: Maybe<Scalars['String']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  partition?: Maybe<Scalars['String']['output']>;
  pimsCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  pimsDeletedAt?: Maybe<Scalars['DateTime']['output']>;
  pimsId?: Maybe<Scalars['String']['output']>;
  pimsUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  practiceId?: Maybe<Scalars['UUID']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type LanguageSyncInput = {
  covetrusId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  deletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  entity?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  integration?: InputMaybe<Scalars['String']['input']>;
  integrationId?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  partition?: InputMaybe<Scalars['String']['input']>;
  pimsCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  pimsDeletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  pimsId?: InputMaybe<Scalars['String']['input']>;
  pimsUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type LocaleSync = {
  __typename?: 'LocaleSync';
  covetrusId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  entity?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['UUID']['output']>;
  integration?: Maybe<Scalars['String']['output']>;
  integrationId?: Maybe<Scalars['String']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  partition?: Maybe<Scalars['String']['output']>;
  pimsCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  pimsDeletedAt?: Maybe<Scalars['DateTime']['output']>;
  pimsId?: Maybe<Scalars['String']['output']>;
  pimsUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  practiceId?: Maybe<Scalars['UUID']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type LocaleSyncInput = {
  covetrusId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  deletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  entity?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  integration?: InputMaybe<Scalars['String']['input']>;
  integrationId?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  partition?: InputMaybe<Scalars['String']['input']>;
  pimsCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  pimsDeletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  pimsId?: InputMaybe<Scalars['String']['input']>;
  pimsUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type Logout = {
  __typename?: 'Logout';
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type MarkedAsFetched = {
  __typename?: 'MarkedAsFetched';
  rowsAffected?: Maybe<Scalars['Int']['output']>;
};

export type MarkedAsFetchedInput = {
  entityType: Scalars['String']['input'];
  partnerHookId: Scalars['UUID']['input'];
};

export type OwnershipSync = {
  __typename?: 'OwnershipSync';
  clientId?: Maybe<Scalars['UUID']['output']>;
  clientIntegrationId?: Maybe<Scalars['String']['output']>;
  covetrusId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  entity?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['UUID']['output']>;
  integration?: Maybe<Scalars['String']['output']>;
  integrationId?: Maybe<Scalars['String']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  partition?: Maybe<Scalars['String']['output']>;
  patientId?: Maybe<Scalars['UUID']['output']>;
  patientIntegrationId?: Maybe<Scalars['String']['output']>;
  percentage?: Maybe<Scalars['Float']['output']>;
  pimsClientId?: Maybe<Scalars['String']['output']>;
  pimsCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  pimsDeletedAt?: Maybe<Scalars['DateTime']['output']>;
  pimsId?: Maybe<Scalars['String']['output']>;
  pimsPatientId?: Maybe<Scalars['String']['output']>;
  pimsUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  practiceId?: Maybe<Scalars['UUID']['output']>;
  relationship?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type OwnershipSyncInput = {
  clientId?: InputMaybe<Scalars['UUID']['input']>;
  clientIntegrationId?: InputMaybe<Scalars['String']['input']>;
  covetrusId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  deletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  entity?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  integration?: InputMaybe<Scalars['String']['input']>;
  integrationId?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  partition?: InputMaybe<Scalars['String']['input']>;
  patientId?: InputMaybe<Scalars['UUID']['input']>;
  patientIntegrationId?: InputMaybe<Scalars['String']['input']>;
  percentage?: InputMaybe<Scalars['Float']['input']>;
  pimsClientId?: InputMaybe<Scalars['String']['input']>;
  pimsCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  pimsDeletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  pimsId?: InputMaybe<Scalars['String']['input']>;
  pimsPatientId?: InputMaybe<Scalars['String']['input']>;
  pimsUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  relationship?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type Partner = {
  __typename?: 'Partner';
  apiKey?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['UUID']['output']>;
  isEnabled?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  singleClientOwnership?: Maybe<Scalars['Boolean']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type PartnerHook = {
  __typename?: 'PartnerHook';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  headers?: Maybe<Array<Maybe<Header>>>;
  id?: Maybe<Scalars['UUID']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  parser?: Maybe<Scalars['String']['output']>;
  partnerId?: Maybe<Scalars['UUID']['output']>;
  practiceId?: Maybe<Scalars['UUID']['output']>;
  sendAppointment?: Maybe<Scalars['Boolean']['output']>;
  sendAppointmentStatus?: Maybe<Scalars['Boolean']['output']>;
  sendAppointmentType?: Maybe<Scalars['Boolean']['output']>;
  sendBlockoff?: Maybe<Scalars['Boolean']['output']>;
  sendBreed?: Maybe<Scalars['Boolean']['output']>;
  sendClient?: Maybe<Scalars['Boolean']['output']>;
  sendClientPhoneNumber?: Maybe<Scalars['Boolean']['output']>;
  sendCurrency?: Maybe<Scalars['Boolean']['output']>;
  sendEmployee?: Maybe<Scalars['Boolean']['output']>;
  sendInvoice?: Maybe<Scalars['Boolean']['output']>;
  sendLanguage?: Maybe<Scalars['Boolean']['output']>;
  sendLocale?: Maybe<Scalars['Boolean']['output']>;
  sendOwnership?: Maybe<Scalars['Boolean']['output']>;
  sendPatient?: Maybe<Scalars['Boolean']['output']>;
  sendPatientReminder?: Maybe<Scalars['Boolean']['output']>;
  sendPractice?: Maybe<Scalars['Boolean']['output']>;
  sendResource?: Maybe<Scalars['Boolean']['output']>;
  sendSex?: Maybe<Scalars['Boolean']['output']>;
  sendSpecies?: Maybe<Scalars['Boolean']['output']>;
  sendTimezone?: Maybe<Scalars['Boolean']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type PartnerInput = {
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  singleClientOwnership?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PartnerUpdateInput = {
  id: Scalars['UUID']['input'];
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  singleClientOwnership?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Patient = {
  __typename?: 'Patient';
  allergies?: Maybe<Scalars['String']['output']>;
  birthdate?: Maybe<Scalars['DateTime']['output']>;
  breed?: Maybe<BreedSync>;
  color?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  entity?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['UUID']['output']>;
  integration?: Maybe<Scalars['String']['output']>;
  integrationId?: Maybe<Scalars['String']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isAgeEstimate?: Maybe<Scalars['Boolean']['output']>;
  isDeceased?: Maybe<Scalars['Boolean']['output']>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  isMixedBreed?: Maybe<Scalars['Boolean']['output']>;
  isWeightEstimate?: Maybe<Scalars['Boolean']['output']>;
  markings?: Maybe<Scalars['String']['output']>;
  microchip?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  partition?: Maybe<Scalars['String']['output']>;
  patientStatus?: Maybe<Scalars['String']['output']>;
  pimsClientId?: Maybe<Scalars['String']['output']>;
  pimsCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  pimsDeletedAt?: Maybe<Scalars['DateTime']['output']>;
  pimsSexId?: Maybe<Scalars['String']['output']>;
  pimsSpeciesId?: Maybe<Scalars['String']['output']>;
  pimsUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  practiceId?: Maybe<Scalars['UUID']['output']>;
  rabiesId?: Maybe<Scalars['String']['output']>;
  registrationId?: Maybe<Scalars['String']['output']>;
  secondBreed?: Maybe<PatientBreedSync>;
  sex?: Maybe<PatientSexSync>;
  species?: Maybe<PatientSpeciesSync>;
  thirdBreed?: Maybe<PatientBreedSync>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  weight?: Maybe<Scalars['Float']['output']>;
  weightUnit?: Maybe<Scalars['String']['output']>;
};

export type PatientBreedSync = {
  __typename?: 'PatientBreedSync';
  integrationId?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type PatientBreedSyncInput = {
  integrationId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type PatientClientSyncInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  homeNumber?: InputMaybe<Scalars['String']['input']>;
  integrationId?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  mobileNumber?: InputMaybe<Scalars['String']['input']>;
};

export type PatientReminder = {
  __typename?: 'PatientReminder';
  clientId?: Maybe<Scalars['UUID']['output']>;
  companyId?: Maybe<Scalars['UUID']['output']>;
  covetrusId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  due?: Maybe<Scalars['String']['output']>;
  employeeId?: Maybe<Scalars['UUID']['output']>;
  entity?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['UUID']['output']>;
  integration?: Maybe<Scalars['String']['output']>;
  integrationId?: Maybe<Scalars['String']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  isSatisfied?: Maybe<Scalars['Boolean']['output']>;
  isVaccine?: Maybe<Scalars['Boolean']['output']>;
  numberOfDays?: Maybe<Scalars['Int32']['output']>;
  partition?: Maybe<Scalars['String']['output']>;
  patientId?: Maybe<Scalars['UUID']['output']>;
  pimsCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  pimsDeletedAt?: Maybe<Scalars['DateTime']['output']>;
  pimsId?: Maybe<Scalars['String']['output']>;
  pimsPatientId?: Maybe<Scalars['String']['output']>;
  pimsUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  practiceId?: Maybe<Scalars['UUID']['output']>;
  reminderType?: Maybe<Scalars['String']['output']>;
  startReminding?: Maybe<Scalars['String']['output']>;
  stopReminding?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type PatientReminderClient = {
  firstName?: InputMaybe<Scalars['String']['input']>;
  integrationId?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
};

export type PatientReminderEmployee = {
  firstName?: InputMaybe<Scalars['String']['input']>;
  integrationId?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
};

export type PatientReminderPatient = {
  integrationId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type PatientReminderSync = {
  __typename?: 'PatientReminderSync';
  id?: Maybe<Scalars['UUID']['output']>;
  integrationId?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type PatientReminderSyncInput = {
  client?: InputMaybe<PatientReminderClient>;
  covetrusId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  due?: InputMaybe<Scalars['DateTime']['input']>;
  employee?: InputMaybe<PatientReminderEmployee>;
  entity?: InputMaybe<Scalars['String']['input']>;
  integration?: InputMaybe<Scalars['String']['input']>;
  integrationId?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  isSatisfied?: InputMaybe<Scalars['Boolean']['input']>;
  isVaccine?: InputMaybe<Scalars['Boolean']['input']>;
  numberOfDays?: InputMaybe<Scalars['Int32']['input']>;
  partition?: InputMaybe<Scalars['String']['input']>;
  patient?: InputMaybe<PatientReminderPatient>;
  pimsCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  pimsDeletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  pimsId?: InputMaybe<Scalars['String']['input']>;
  pimsPatientId?: InputMaybe<Scalars['String']['input']>;
  pimsUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  reminderType?: InputMaybe<Scalars['String']['input']>;
  startReminding?: InputMaybe<Scalars['DateTime']['input']>;
  stopReminding?: InputMaybe<Scalars['DateTime']['input']>;
};

export type PatientSexSync = {
  __typename?: 'PatientSexSync';
  integrationId?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type PatientSexSyncInput = {
  integrationId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type PatientSpeciesSync = {
  __typename?: 'PatientSpeciesSync';
  integrationId?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type PatientSpeciesSyncInput = {
  integrationId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type PatientSync = {
  __typename?: 'PatientSync';
  id?: Maybe<Scalars['UUID']['output']>;
  integrationId?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type PatientSyncInput = {
  allergies?: InputMaybe<Scalars['String']['input']>;
  birthdate?: InputMaybe<Scalars['DateTime']['input']>;
  breed?: InputMaybe<PatientBreedSyncInput>;
  client?: InputMaybe<PatientClientSyncInput>;
  color?: InputMaybe<Scalars['String']['input']>;
  entity?: InputMaybe<Scalars['String']['input']>;
  integration?: InputMaybe<Scalars['String']['input']>;
  integrationId?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isAgeEstimate?: InputMaybe<Scalars['Boolean']['input']>;
  isDeceased?: InputMaybe<Scalars['Boolean']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  isMixedBreed?: InputMaybe<Scalars['Boolean']['input']>;
  isWeightEstimate?: InputMaybe<Scalars['Boolean']['input']>;
  markings?: InputMaybe<Scalars['String']['input']>;
  microchip?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  partition?: InputMaybe<Scalars['String']['input']>;
  patientStatus?: InputMaybe<Scalars['String']['input']>;
  pimsClientId?: InputMaybe<Scalars['String']['input']>;
  pimsCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  pimsDeletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  pimsId?: InputMaybe<Scalars['String']['input']>;
  pimsUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  rabiesId?: InputMaybe<Scalars['String']['input']>;
  registrationId?: InputMaybe<Scalars['String']['input']>;
  secondBreed?: InputMaybe<PatientBreedSyncInput>;
  sex?: InputMaybe<PatientSexSyncInput>;
  species?: InputMaybe<PatientSpeciesSyncInput>;
  thirdBreed?: InputMaybe<PatientBreedSyncInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  weight?: InputMaybe<Scalars['Float']['input']>;
  weightUnit?: InputMaybe<Scalars['String']['input']>;
};

export type Practice = {
  __typename?: 'Practice';
  colorCode?: Maybe<Scalars['String']['output']>;
  contact?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  currencyId?: Maybe<Scalars['UUID']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  facebookUrl?: Maybe<Scalars['String']['output']>;
  goLiveDate?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['UUID']['output']>;
  installation?: Maybe<Scalars['String']['output']>;
  integration?: Maybe<Scalars['String']['output']>;
  integrationId?: Maybe<Scalars['String']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  languageId?: Maybe<Scalars['UUID']['output']>;
  localeId?: Maybe<Scalars['UUID']['output']>;
  migratedFrom?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  privateKey?: Maybe<Scalars['String']['output']>;
  serverId?: Maybe<Scalars['UUID']['output']>;
  siteId?: Maybe<Scalars['Int64']['output']>;
  timezoneId?: Maybe<Scalars['UUID']['output']>;
  timezoneName?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  websiteUrl?: Maybe<Scalars['String']['output']>;
};

export type ResetPracticeInput = {
  id: Scalars['UUID']['input'];
};

export type ResetSuccess = {
  __typename?: 'ResetSuccess';
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type Resource = {
  integrationId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type ResourceSync = {
  __typename?: 'ResourceSync';
  covetrusId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  employeeId?: Maybe<Scalars['UUID']['output']>;
  entity?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['UUID']['output']>;
  integration?: Maybe<Scalars['String']['output']>;
  integrationId?: Maybe<Scalars['String']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  partition?: Maybe<Scalars['String']['output']>;
  pimsCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  pimsDeletedAt?: Maybe<Scalars['DateTime']['output']>;
  pimsEmployeeId?: Maybe<Scalars['String']['output']>;
  pimsId?: Maybe<Scalars['String']['output']>;
  pimsUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  practiceId?: Maybe<Scalars['UUID']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ResourceSyncInput = {
  covetrusId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  deletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  employeeId?: InputMaybe<Scalars['UUID']['input']>;
  entity?: InputMaybe<Scalars['String']['input']>;
  integration?: InputMaybe<Scalars['String']['input']>;
  integrationId?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  partition?: InputMaybe<Scalars['String']['input']>;
  pimsCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  pimsDeletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  pimsEmployeeId?: InputMaybe<Scalars['String']['input']>;
  pimsId?: InputMaybe<Scalars['String']['input']>;
  pimsUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type RootMutation = {
  __typename?: 'RootMutation';
  /** Get Session Token */
  authenticate?: Maybe<Session>;
  /** Create Partner */
  createPartner?: Maybe<Partner>;
  /** Create Partner Hook */
  createPartnerHook?: Maybe<PartnerHook>;
  /** Create Practice */
  createPractice?: Maybe<Practice>;
  /** Logout */
  logout?: Maybe<Logout>;
  /** markAsFetched */
  markEntitiesAsFetched?: Maybe<MarkedAsFetched>;
  provisionSite?: Maybe<Practice>;
  /** Reset Practice Sync */
  resetPractice?: Maybe<ResetSuccess>;
  /** Sync Appointment Statuses */
  syncAppointmentStatuses?: Maybe<Array<Maybe<AppointmentStatusSync>>>;
  /** Sync AppointmentTypes */
  syncAppointmentTypes?: Maybe<Array<Maybe<AppointmentTypeSync>>>;
  /** Sync Appointments */
  syncAppointments?: Maybe<Array<Maybe<AppointmentSync>>>;
  /** Sync Blockoffs */
  syncBlockoffs?: Maybe<Array<Maybe<BlockoffSync>>>;
  /** Not Implemented Yet - Sync Breeds */
  syncBreeds?: Maybe<Array<Maybe<BreedSync>>>;
  /** Sync Clients */
  syncClients?: Maybe<Array<Maybe<ClientSync>>>;
  /** Sync Invoices */
  syncInvoices?: Maybe<Array<Maybe<InvoiceSync>>>;
  /** Sync Patient Reminders */
  syncPatientReminders?: Maybe<Array<Maybe<PatientReminderSync>>>;
  /** Sync Patients */
  syncPatients?: Maybe<Array<Maybe<PatientSync>>>;
  /** Sync Resources */
  syncResources?: Maybe<Array<Maybe<ResourceSync>>>;
  /** Sync Sexes */
  syncSexes?: Maybe<Array<Maybe<SexSync>>>;
  /** Not Implemented Yet - Sync Species */
  syncSpecies?: Maybe<Array<Maybe<SpeciesSync>>>;
  /** Update Partner */
  updatePartner?: Maybe<Partner>;
  /** Update Partner Hook */
  updatePartnerHook?: Maybe<PartnerHook>;
  /** Update Practice */
  updatePractice?: Maybe<Practice>;
};


export type RootMutationAuthenticateArgs = {
  token: Scalars['String']['input'];
};


export type RootMutationCreatePartnerArgs = {
  partner: PartnerInput;
};


export type RootMutationCreatePartnerHookArgs = {
  partnerHook: CreatePartnerHookInput;
};


export type RootMutationCreatePracticeArgs = {
  practice: CreatePracticeInput;
};


export type RootMutationMarkEntitiesAsFetchedArgs = {
  markEntitiesAsFetched: MarkedAsFetchedInput;
};


export type RootMutationProvisionSiteArgs = {
  site?: InputMaybe<SyncSiteInput>;
};


export type RootMutationResetPracticeArgs = {
  practice: ResetPracticeInput;
};


export type RootMutationSyncAppointmentStatusesArgs = {
  appointmentStatuses?: InputMaybe<Array<InputMaybe<AppointmentStatusSyncInput>>>;
};


export type RootMutationSyncAppointmentTypesArgs = {
  appointmentTypes?: InputMaybe<Array<InputMaybe<AppointmentTypeSyncInput>>>;
};


export type RootMutationSyncAppointmentsArgs = {
  appointments?: InputMaybe<Array<InputMaybe<AppointmentSyncInput>>>;
};


export type RootMutationSyncBlockoffsArgs = {
  blockoffs?: InputMaybe<Array<InputMaybe<BlockoffSyncInput>>>;
};


export type RootMutationSyncBreedsArgs = {
  breeds?: InputMaybe<Array<InputMaybe<BreedSyncInput>>>;
};


export type RootMutationSyncClientsArgs = {
  clients?: InputMaybe<Array<InputMaybe<ClientSyncInput>>>;
};


export type RootMutationSyncInvoicesArgs = {
  invoices?: InputMaybe<Array<InputMaybe<InvoiceSyncInput>>>;
};


export type RootMutationSyncPatientRemindersArgs = {
  patientReminders?: InputMaybe<Array<InputMaybe<PatientReminderSyncInput>>>;
};


export type RootMutationSyncPatientsArgs = {
  patients?: InputMaybe<Array<InputMaybe<PatientSyncInput>>>;
};


export type RootMutationSyncResourcesArgs = {
  ResourcesSyncInput?: InputMaybe<Array<InputMaybe<ResourceSyncInput>>>;
};


export type RootMutationSyncSexesArgs = {
  sexes?: InputMaybe<Array<InputMaybe<SexSyncInput>>>;
};


export type RootMutationSyncSpeciesArgs = {
  species?: InputMaybe<Array<InputMaybe<SpeciesSyncInput>>>;
};


export type RootMutationUpdatePartnerArgs = {
  partner: PartnerUpdateInput;
};


export type RootMutationUpdatePartnerHookArgs = {
  partnerHook: UpdatePartnerHookInput;
};


export type RootMutationUpdatePracticeArgs = {
  practice: UpdatePracticeInput;
};

export type RootQuery = {
  __typename?: 'RootQuery';
  /** Get Entities List */
  getEntities?: Maybe<Array<Maybe<EntityType>>>;
  /** Get Status */
  hello?: Maybe<Hello>;
  /** Get Partner */
  partner?: Maybe<Partner>;
  /** Get Partner Hook */
  partnerHook?: Maybe<PartnerHook>;
  /** Get Partner Hook */
  partnerHooks?: Maybe<Array<Maybe<PartnerHook>>>;
  partners?: Maybe<Array<Maybe<Partner>>>;
  /** Get Practice */
  practice?: Maybe<Practice>;
  /** Get Practices */
  practices?: Maybe<Array<Maybe<Practice>>>;
};


export type RootQueryPartnerArgs = {
  id: Scalars['UUID']['input'];
};


export type RootQueryPartnerHookArgs = {
  id: Scalars['UUID']['input'];
};


export type RootQueryPracticeArgs = {
  id: Scalars['UUID']['input'];
};

export type Session = {
  __typename?: 'Session';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['UUID']['output']>;
  isSuper?: Maybe<Scalars['Boolean']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  partnerId?: Maybe<Scalars['UUID']['output']>;
  token?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type Sex = {
  __typename?: 'Sex';
  covetrusId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  entity?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['UUID']['output']>;
  integration?: Maybe<Scalars['String']['output']>;
  integrationId?: Maybe<Scalars['String']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  partition?: Maybe<Scalars['String']['output']>;
  pimsCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  pimsDeletedAt?: Maybe<Scalars['DateTime']['output']>;
  pimsId?: Maybe<Scalars['String']['output']>;
  pimsSpeciesId?: Maybe<Scalars['String']['output']>;
  pimsUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  practiceId?: Maybe<Scalars['UUID']['output']>;
  speciesId?: Maybe<Scalars['UUID']['output']>;
  speciesIntegrationId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type SexSync = {
  __typename?: 'SexSync';
  id?: Maybe<Scalars['UUID']['output']>;
  integrationId?: Maybe<Scalars['String']['output']>;
  species?: Maybe<SpeciesSync>;
  status?: Maybe<Scalars['String']['output']>;
};

export type SexSyncInput = {
  covetrusId?: InputMaybe<Scalars['String']['input']>;
  entity?: InputMaybe<Scalars['String']['input']>;
  integration?: InputMaybe<Scalars['String']['input']>;
  integrationId?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  partition?: InputMaybe<Scalars['String']['input']>;
  pimsCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  pimsDeletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  pimsId?: InputMaybe<Scalars['String']['input']>;
  pimsSpeciesId?: InputMaybe<Scalars['String']['input']>;
  pimsUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  species?: InputMaybe<SpeciesSyncInput>;
  speciesIntegrationId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
};

export type Site = {
  __typename?: 'Site';
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
};

export type Species = {
  __typename?: 'Species';
  covetrusId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  entity?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['UUID']['output']>;
  integration?: Maybe<Scalars['String']['output']>;
  integrationId?: Maybe<Scalars['String']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  partition?: Maybe<Scalars['String']['output']>;
  pimsCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  pimsDeletedAt?: Maybe<Scalars['DateTime']['output']>;
  pimsId?: Maybe<Scalars['String']['output']>;
  pimsUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  practiceId?: Maybe<Scalars['UUID']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type SpeciesSync = {
  __typename?: 'SpeciesSync';
  id?: Maybe<Scalars['UUID']['output']>;
  integrationId?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type SpeciesSyncInput = {
  backgroundColor?: InputMaybe<Scalars['String']['input']>;
  covetrusId?: InputMaybe<Scalars['String']['input']>;
  deadIcon?: InputMaybe<Scalars['String']['input']>;
  entity?: InputMaybe<Scalars['String']['input']>;
  icon?: InputMaybe<Scalars['String']['input']>;
  inactiveIcon?: InputMaybe<Scalars['String']['input']>;
  integration?: InputMaybe<Scalars['String']['input']>;
  integrationId: Scalars['String']['input'];
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  partition?: InputMaybe<Scalars['String']['input']>;
  pimsCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  pimsDeletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  pimsId?: InputMaybe<Scalars['String']['input']>;
  pimsSpeciesId?: InputMaybe<Scalars['String']['input']>;
  pimsUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  speciesIntegrationId?: InputMaybe<Scalars['String']['input']>;
};

export type SyncInvoiceStatus = {
  integrationId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type SyncInvoiceType = {
  integrationId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type SyncSiteInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export type TimezoneSync = {
  __typename?: 'TimezoneSync';
  covetrusId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  entity?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['UUID']['output']>;
  integration?: Maybe<Scalars['String']['output']>;
  integrationId?: Maybe<Scalars['String']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  partition?: Maybe<Scalars['String']['output']>;
  pimsCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  pimsDeletedAt?: Maybe<Scalars['DateTime']['output']>;
  pimsId?: Maybe<Scalars['String']['output']>;
  pimsUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  practiceId?: Maybe<Scalars['UUID']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type TimezoneSyncInput = {
  covetrusId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  deletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  entity?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  integration?: InputMaybe<Scalars['String']['input']>;
  integrationId?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  partition?: InputMaybe<Scalars['String']['input']>;
  pimsCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  pimsDeletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  pimsId?: InputMaybe<Scalars['String']['input']>;
  pimsUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type UnimplementedMutations = {
  __typename?: 'UnimplementedMutations';
  /** Not Implemented Yet - Sync Appointment Employees */
  syncAppointmentEmployees?: Maybe<Array<Maybe<AppointmentEmployeeSync>>>;
  /** Not Implemented Yet - Sync Client Phone Numbers */
  syncClientPhoneNumbers?: Maybe<ClientPhoneNumberSync>;
  /** Not Implemented Yet - Sync Currencies */
  syncCurrencies?: Maybe<Array<Maybe<CurrencySync>>>;
  /** Not Implemented Yet - Sync Employees */
  syncEmployee?: Maybe<Array<Maybe<EmployeeSync>>>;
  /** Not Implemented Yet - Sync Languages */
  syncLanguages?: Maybe<Array<Maybe<LanguageSync>>>;
  /** Not Implemented Yet - Sync Locales */
  syncLocales?: Maybe<Array<Maybe<LocaleSync>>>;
  /** Not Implemented Yet - Sync Ownerships */
  syncOwnerships?: Maybe<Array<Maybe<OwnershipSync>>>;
  /** Not Implemented Yet - Sync Timezones */
  syncTimezones?: Maybe<Array<Maybe<TimezoneSync>>>;
};


export type UnimplementedMutationsSyncAppointmentEmployeesArgs = {
  appointmentEmployees?: InputMaybe<Array<InputMaybe<AppointmentEmployeesSyncInput>>>;
};


export type UnimplementedMutationsSyncClientPhoneNumbersArgs = {
  clientPhoneNumbers?: InputMaybe<Array<InputMaybe<ClientPhoneNumberSyncInput>>>;
};


export type UnimplementedMutationsSyncCurrenciesArgs = {
  currencies?: InputMaybe<Array<InputMaybe<CurrencySyncInput>>>;
};


export type UnimplementedMutationsSyncEmployeeArgs = {
  employees?: InputMaybe<Array<InputMaybe<EmployeeSyncInput>>>;
};


export type UnimplementedMutationsSyncLanguagesArgs = {
  languages?: InputMaybe<Array<InputMaybe<LanguageSyncInput>>>;
};


export type UnimplementedMutationsSyncLocalesArgs = {
  locales?: InputMaybe<Array<InputMaybe<LocaleSyncInput>>>;
};


export type UnimplementedMutationsSyncOwnershipsArgs = {
  ownerships?: InputMaybe<Array<InputMaybe<OwnershipSyncInput>>>;
};


export type UnimplementedMutationsSyncTimezonesArgs = {
  timezones?: InputMaybe<Array<InputMaybe<TimezoneSyncInput>>>;
};

export type UpdatePartnerHookInput = {
  headers?: InputMaybe<Array<InputMaybe<HeadersInput>>>;
  id: Scalars['UUID']['input'];
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  parser?: InputMaybe<Scalars['String']['input']>;
  partnerId?: InputMaybe<Scalars['UUID']['input']>;
  practiceId?: InputMaybe<Scalars['UUID']['input']>;
  sendAppointment?: InputMaybe<Scalars['Boolean']['input']>;
  sendAppointmentStatus?: InputMaybe<Scalars['Boolean']['input']>;
  sendAppointmentType?: InputMaybe<Scalars['Boolean']['input']>;
  sendBlockoff?: InputMaybe<Scalars['Boolean']['input']>;
  sendBreed?: InputMaybe<Scalars['Boolean']['input']>;
  sendClient?: InputMaybe<Scalars['Boolean']['input']>;
  sendClientPhoneNumber?: InputMaybe<Scalars['Boolean']['input']>;
  sendCurrency?: InputMaybe<Scalars['Boolean']['input']>;
  sendEmployee?: InputMaybe<Scalars['Boolean']['input']>;
  sendInvoice?: InputMaybe<Scalars['Boolean']['input']>;
  sendLanguage?: InputMaybe<Scalars['Boolean']['input']>;
  sendLocale?: InputMaybe<Scalars['Boolean']['input']>;
  sendOwnership?: InputMaybe<Scalars['Boolean']['input']>;
  sendPatient?: InputMaybe<Scalars['Boolean']['input']>;
  sendPatientReminder?: InputMaybe<Scalars['Boolean']['input']>;
  sendPractice?: InputMaybe<Scalars['Boolean']['input']>;
  sendResource?: InputMaybe<Scalars['Boolean']['input']>;
  sendSex?: InputMaybe<Scalars['Boolean']['input']>;
  sendSpecies?: InputMaybe<Scalars['Boolean']['input']>;
  sendTimezone?: InputMaybe<Scalars['Boolean']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type UpdatePracticeInput = {
  currencyId?: InputMaybe<Scalars['UUID']['input']>;
  id: Scalars['UUID']['input'];
  installation?: InputMaybe<Scalars['String']['input']>;
  integration?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  languageId?: InputMaybe<Scalars['UUID']['input']>;
  localeId?: InputMaybe<Scalars['UUID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  serverId?: InputMaybe<Scalars['UUID']['input']>;
  siteId?: InputMaybe<Scalars['Int64']['input']>;
  timezoneId?: InputMaybe<Scalars['UUID']['input']>;
  timezoneName?: InputMaybe<Scalars['String']['input']>;
  timezoneOffset?: InputMaybe<Scalars['String']['input']>;
};

export type CreatePartnerMutationVariables = Exact<{
  partner: PartnerInput;
}>;


export type CreatePartnerMutation = { __typename?: 'RootMutation', createPartner?: { __typename?: 'Partner', id?: any | null, name?: string | null } | null };

export type UpdatePartnerMutationVariables = Exact<{
  partner: PartnerUpdateInput;
}>;


export type UpdatePartnerMutation = { __typename?: 'RootMutation', updatePartner?: { __typename?: 'Partner', id?: any | null, name?: string | null, singleClientOwnership?: boolean | null } | null };

export type CreatePracticeMutationVariables = Exact<{
  practice: CreatePracticeInput;
}>;


export type CreatePracticeMutation = { __typename?: 'RootMutation', createPractice?: { __typename?: 'Practice', id?: any | null, name?: string | null, colorCode?: string | null, contact?: string | null, currencyId?: any | null, email?: string | null, facebookUrl?: string | null, goLiveDate?: any | null, installation?: string | null, integration?: string | null, integrationId?: string | null, isActive?: boolean | null, isDeleted?: boolean | null, label?: string | null, languageId?: any | null, localeId?: any | null, migratedFrom?: string | null, serverId?: any | null, siteId?: any | null, websiteUrl?: string | null, timezoneId?: any | null, privateKey?: string | null, createdAt?: any | null, updatedAt?: any | null } | null };

export type UpdatePracticeMutationVariables = Exact<{
  practice: UpdatePracticeInput;
}>;


export type UpdatePracticeMutation = { __typename?: 'RootMutation', updatePractice?: { __typename?: 'Practice', id?: any | null, name?: string | null, colorCode?: string | null, contact?: string | null, currencyId?: any | null, email?: string | null, facebookUrl?: string | null, goLiveDate?: any | null, installation?: string | null, integration?: string | null, integrationId?: string | null, isActive?: boolean | null, isDeleted?: boolean | null, label?: string | null, languageId?: any | null, localeId?: any | null, migratedFrom?: string | null, serverId?: any | null, siteId?: any | null, websiteUrl?: string | null, timezoneId?: any | null, privateKey?: string | null, createdAt?: any | null, updatedAt?: any | null } | null };

export type CreatePartnerHookMutationVariables = Exact<{
  partnerHook: CreatePartnerHookInput;
}>;


export type CreatePartnerHookMutation = { __typename?: 'RootMutation', createPartnerHook?: { __typename?: 'PartnerHook', id?: any | null, name?: string | null, partnerId?: any | null, practiceId?: any | null, sendAppointment?: boolean | null, sendAppointmentStatus?: boolean | null, sendAppointmentType?: boolean | null, sendBlockoff?: boolean | null, sendBreed?: boolean | null, sendClient?: boolean | null, sendClientPhoneNumber?: boolean | null, sendCurrency?: boolean | null, sendEmployee?: boolean | null, sendInvoice?: boolean | null, sendLanguage?: boolean | null, sendLocale?: boolean | null, sendPatientReminder?: boolean | null, sendPatient?: boolean | null, sendOwnership?: boolean | null, sendPractice?: boolean | null, sendResource?: boolean | null, sendSex?: boolean | null, sendSpecies?: boolean | null, sendTimezone?: boolean | null, parser?: string | null, isActive?: boolean | null, url?: string | null, createdAt?: any | null, updatedAt?: any | null, headers?: Array<{ __typename?: 'Header', name?: string | null, value?: string | null } | null> | null } | null };

export type UpdatePartnerHookMutationVariables = Exact<{
  partnerHook: UpdatePartnerHookInput;
}>;


export type UpdatePartnerHookMutation = { __typename?: 'RootMutation', updatePartnerHook?: { __typename?: 'PartnerHook', id?: any | null, name?: string | null, partnerId?: any | null, practiceId?: any | null, sendAppointment?: boolean | null, sendAppointmentStatus?: boolean | null, sendAppointmentType?: boolean | null, sendBlockoff?: boolean | null, sendBreed?: boolean | null, sendClient?: boolean | null, sendClientPhoneNumber?: boolean | null, sendCurrency?: boolean | null, sendEmployee?: boolean | null, sendInvoice?: boolean | null, sendLanguage?: boolean | null, sendLocale?: boolean | null, sendPatientReminder?: boolean | null, sendPatient?: boolean | null, sendOwnership?: boolean | null, sendPractice?: boolean | null, sendResource?: boolean | null, sendSex?: boolean | null, sendSpecies?: boolean | null, sendTimezone?: boolean | null, parser?: string | null, isActive?: boolean | null, url?: string | null, createdAt?: any | null, updatedAt?: any | null, headers?: Array<{ __typename?: 'Header', name?: string | null, value?: string | null } | null> | null } | null };

export type ResetPracticeMutationVariables = Exact<{
  practice: ResetPracticeInput;
}>;


export type ResetPracticeMutation = { __typename?: 'RootMutation', resetPractice?: { __typename?: 'ResetSuccess', success?: boolean | null } | null };

export type MarkEntitiesAsFetchedMutationVariables = Exact<{
  markEntitiesAsFetched: MarkedAsFetchedInput;
}>;


export type MarkEntitiesAsFetchedMutation = { __typename?: 'RootMutation', markEntitiesAsFetched?: { __typename?: 'MarkedAsFetched', rowsAffected?: number | null } | null };

export type PartnersQueryVariables = Exact<{ [key: string]: never; }>;


export type PartnersQuery = { __typename?: 'RootQuery', partners?: Array<{ __typename?: 'Partner', id?: any | null, name?: string | null, isEnabled?: boolean | null, singleClientOwnership?: boolean | null, createdAt?: any | null } | null> | null };

export type PartnerQueryVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;


export type PartnerQuery = { __typename?: 'RootQuery', partner?: { __typename?: 'Partner', id?: any | null, isEnabled?: boolean | null, singleClientOwnership?: boolean | null, name?: string | null, createdAt?: any | null, updatedAt?: any | null } | null };

export type PracticesQueryVariables = Exact<{ [key: string]: never; }>;


export type PracticesQuery = { __typename?: 'RootQuery', practices?: Array<{ __typename?: 'Practice', id?: any | null, name?: string | null, integration?: string | null, isActive?: boolean | null, createdAt?: any | null } | null> | null };

export type PracticeQueryVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;


export type PracticeQuery = { __typename?: 'RootQuery', practice?: { __typename?: 'Practice', id?: any | null, name?: string | null, colorCode?: string | null, contact?: string | null, currencyId?: any | null, email?: string | null, facebookUrl?: string | null, goLiveDate?: any | null, installation?: string | null, integration?: string | null, integrationId?: string | null, isActive?: boolean | null, isDeleted?: boolean | null, label?: string | null, languageId?: any | null, localeId?: any | null, migratedFrom?: string | null, serverId?: any | null, siteId?: any | null, websiteUrl?: string | null, timezoneId?: any | null, privateKey?: string | null, createdAt?: any | null, updatedAt?: any | null } | null };

export type PartnerHooksQueryVariables = Exact<{ [key: string]: never; }>;


export type PartnerHooksQuery = { __typename?: 'RootQuery', partnerHooks?: Array<{ __typename?: 'PartnerHook', id?: any | null, name?: string | null, partnerId?: any | null, practiceId?: any | null, parser?: string | null, sendAppointment?: boolean | null, sendAppointmentStatus?: boolean | null, sendAppointmentType?: boolean | null, sendBlockoff?: boolean | null, sendBreed?: boolean | null, sendClient?: boolean | null, sendClientPhoneNumber?: boolean | null, sendCurrency?: boolean | null, sendEmployee?: boolean | null, sendInvoice?: boolean | null, sendLanguage?: boolean | null, sendLocale?: boolean | null, sendPatientReminder?: boolean | null, sendPatient?: boolean | null, sendOwnership?: boolean | null, sendPractice?: boolean | null, sendResource?: boolean | null, sendSex?: boolean | null, sendSpecies?: boolean | null, sendTimezone?: boolean | null, isActive?: boolean | null, url?: string | null, createdAt?: any | null, updatedAt?: any | null, headers?: Array<{ __typename?: 'Header', name?: string | null, value?: string | null } | null> | null } | null> | null };

export type PartnerHookQueryVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;


export type PartnerHookQuery = { __typename?: 'RootQuery', partnerHook?: { __typename?: 'PartnerHook', id?: any | null, name?: string | null, partnerId?: any | null, practiceId?: any | null, parser?: string | null, sendAppointment?: boolean | null, sendAppointmentStatus?: boolean | null, sendAppointmentType?: boolean | null, sendBlockoff?: boolean | null, sendBreed?: boolean | null, sendClient?: boolean | null, sendClientPhoneNumber?: boolean | null, sendCurrency?: boolean | null, sendEmployee?: boolean | null, sendInvoice?: boolean | null, sendLanguage?: boolean | null, sendLocale?: boolean | null, sendPatientReminder?: boolean | null, sendPatient?: boolean | null, sendOwnership?: boolean | null, sendPractice?: boolean | null, sendResource?: boolean | null, sendSex?: boolean | null, sendSpecies?: boolean | null, sendTimezone?: boolean | null, isActive?: boolean | null, url?: string | null, createdAt?: any | null, updatedAt?: any | null, headers?: Array<{ __typename?: 'Header', name?: string | null, value?: string | null } | null> | null } | null };

export type GetEntitiesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetEntitiesQuery = { __typename?: 'RootQuery', getEntities?: Array<{ __typename?: 'EntityType', name?: string | null, key?: string | null } | null> | null };


export const CreatePartnerDocument = gql`
    mutation createPartner($partner: PartnerInput!) {
  createPartner(partner: $partner) {
    id
    name
  }
}
    `;
export type CreatePartnerMutationFn = Apollo.MutationFunction<CreatePartnerMutation, CreatePartnerMutationVariables>;

/**
 * __useCreatePartnerMutation__
 *
 * To run a mutation, you first call `useCreatePartnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePartnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPartnerMutation, { data, loading, error }] = useCreatePartnerMutation({
 *   variables: {
 *      partner: // value for 'partner'
 *   },
 * });
 */
export function useCreatePartnerMutation(baseOptions?: Apollo.MutationHookOptions<CreatePartnerMutation, CreatePartnerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePartnerMutation, CreatePartnerMutationVariables>(CreatePartnerDocument, options);
      }
export type CreatePartnerMutationHookResult = ReturnType<typeof useCreatePartnerMutation>;
export type CreatePartnerMutationResult = Apollo.MutationResult<CreatePartnerMutation>;
export type CreatePartnerMutationOptions = Apollo.BaseMutationOptions<CreatePartnerMutation, CreatePartnerMutationVariables>;
export const UpdatePartnerDocument = gql`
    mutation updatePartner($partner: PartnerUpdateInput!) {
  updatePartner(partner: $partner) {
    id
    name
    singleClientOwnership
  }
}
    `;
export type UpdatePartnerMutationFn = Apollo.MutationFunction<UpdatePartnerMutation, UpdatePartnerMutationVariables>;

/**
 * __useUpdatePartnerMutation__
 *
 * To run a mutation, you first call `useUpdatePartnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePartnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePartnerMutation, { data, loading, error }] = useUpdatePartnerMutation({
 *   variables: {
 *      partner: // value for 'partner'
 *   },
 * });
 */
export function useUpdatePartnerMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePartnerMutation, UpdatePartnerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePartnerMutation, UpdatePartnerMutationVariables>(UpdatePartnerDocument, options);
      }
export type UpdatePartnerMutationHookResult = ReturnType<typeof useUpdatePartnerMutation>;
export type UpdatePartnerMutationResult = Apollo.MutationResult<UpdatePartnerMutation>;
export type UpdatePartnerMutationOptions = Apollo.BaseMutationOptions<UpdatePartnerMutation, UpdatePartnerMutationVariables>;
export const CreatePracticeDocument = gql`
    mutation createPractice($practice: CreatePracticeInput!) {
  createPractice(practice: $practice) {
    id
    name
    colorCode
    contact
    currencyId
    email
    facebookUrl
    goLiveDate
    installation
    integration
    integrationId
    isActive
    isDeleted
    label
    languageId
    localeId
    migratedFrom
    serverId
    siteId
    websiteUrl
    timezoneId
    privateKey
    createdAt
    updatedAt
  }
}
    `;
export type CreatePracticeMutationFn = Apollo.MutationFunction<CreatePracticeMutation, CreatePracticeMutationVariables>;

/**
 * __useCreatePracticeMutation__
 *
 * To run a mutation, you first call `useCreatePracticeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePracticeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPracticeMutation, { data, loading, error }] = useCreatePracticeMutation({
 *   variables: {
 *      practice: // value for 'practice'
 *   },
 * });
 */
export function useCreatePracticeMutation(baseOptions?: Apollo.MutationHookOptions<CreatePracticeMutation, CreatePracticeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePracticeMutation, CreatePracticeMutationVariables>(CreatePracticeDocument, options);
      }
export type CreatePracticeMutationHookResult = ReturnType<typeof useCreatePracticeMutation>;
export type CreatePracticeMutationResult = Apollo.MutationResult<CreatePracticeMutation>;
export type CreatePracticeMutationOptions = Apollo.BaseMutationOptions<CreatePracticeMutation, CreatePracticeMutationVariables>;
export const UpdatePracticeDocument = gql`
    mutation updatePractice($practice: UpdatePracticeInput!) {
  updatePractice(practice: $practice) {
    id
    name
    colorCode
    contact
    currencyId
    email
    facebookUrl
    goLiveDate
    installation
    integration
    integrationId
    isActive
    isDeleted
    label
    languageId
    localeId
    migratedFrom
    serverId
    siteId
    websiteUrl
    timezoneId
    privateKey
    createdAt
    updatedAt
  }
}
    `;
export type UpdatePracticeMutationFn = Apollo.MutationFunction<UpdatePracticeMutation, UpdatePracticeMutationVariables>;

/**
 * __useUpdatePracticeMutation__
 *
 * To run a mutation, you first call `useUpdatePracticeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePracticeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePracticeMutation, { data, loading, error }] = useUpdatePracticeMutation({
 *   variables: {
 *      practice: // value for 'practice'
 *   },
 * });
 */
export function useUpdatePracticeMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePracticeMutation, UpdatePracticeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePracticeMutation, UpdatePracticeMutationVariables>(UpdatePracticeDocument, options);
      }
export type UpdatePracticeMutationHookResult = ReturnType<typeof useUpdatePracticeMutation>;
export type UpdatePracticeMutationResult = Apollo.MutationResult<UpdatePracticeMutation>;
export type UpdatePracticeMutationOptions = Apollo.BaseMutationOptions<UpdatePracticeMutation, UpdatePracticeMutationVariables>;
export const CreatePartnerHookDocument = gql`
    mutation createPartnerHook($partnerHook: CreatePartnerHookInput!) {
  createPartnerHook(partnerHook: $partnerHook) {
    id
    name
    partnerId
    practiceId
    sendAppointment
    sendAppointmentStatus
    sendAppointmentType
    sendBlockoff
    sendBreed
    sendClient
    sendClientPhoneNumber
    sendCurrency
    sendEmployee
    sendInvoice
    sendLanguage
    sendLocale
    sendPatientReminder
    sendPatient
    sendOwnership
    sendPractice
    sendResource
    sendSex
    sendSpecies
    sendTimezone
    parser
    headers {
      name
      value
    }
    isActive
    url
    createdAt
    updatedAt
  }
}
    `;
export type CreatePartnerHookMutationFn = Apollo.MutationFunction<CreatePartnerHookMutation, CreatePartnerHookMutationVariables>;

/**
 * __useCreatePartnerHookMutation__
 *
 * To run a mutation, you first call `useCreatePartnerHookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePartnerHookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPartnerHookMutation, { data, loading, error }] = useCreatePartnerHookMutation({
 *   variables: {
 *      partnerHook: // value for 'partnerHook'
 *   },
 * });
 */
export function useCreatePartnerHookMutation(baseOptions?: Apollo.MutationHookOptions<CreatePartnerHookMutation, CreatePartnerHookMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePartnerHookMutation, CreatePartnerHookMutationVariables>(CreatePartnerHookDocument, options);
      }
export type CreatePartnerHookMutationHookResult = ReturnType<typeof useCreatePartnerHookMutation>;
export type CreatePartnerHookMutationResult = Apollo.MutationResult<CreatePartnerHookMutation>;
export type CreatePartnerHookMutationOptions = Apollo.BaseMutationOptions<CreatePartnerHookMutation, CreatePartnerHookMutationVariables>;
export const UpdatePartnerHookDocument = gql`
    mutation updatePartnerHook($partnerHook: UpdatePartnerHookInput!) {
  updatePartnerHook(partnerHook: $partnerHook) {
    id
    name
    partnerId
    practiceId
    sendAppointment
    sendAppointmentStatus
    sendAppointmentType
    sendBlockoff
    sendBreed
    sendClient
    sendClientPhoneNumber
    sendCurrency
    sendEmployee
    sendInvoice
    sendLanguage
    sendLocale
    sendPatientReminder
    sendPatient
    sendOwnership
    sendPractice
    sendResource
    sendSex
    sendSpecies
    sendTimezone
    parser
    headers {
      name
      value
    }
    isActive
    url
    createdAt
    updatedAt
  }
}
    `;
export type UpdatePartnerHookMutationFn = Apollo.MutationFunction<UpdatePartnerHookMutation, UpdatePartnerHookMutationVariables>;

/**
 * __useUpdatePartnerHookMutation__
 *
 * To run a mutation, you first call `useUpdatePartnerHookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePartnerHookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePartnerHookMutation, { data, loading, error }] = useUpdatePartnerHookMutation({
 *   variables: {
 *      partnerHook: // value for 'partnerHook'
 *   },
 * });
 */
export function useUpdatePartnerHookMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePartnerHookMutation, UpdatePartnerHookMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePartnerHookMutation, UpdatePartnerHookMutationVariables>(UpdatePartnerHookDocument, options);
      }
export type UpdatePartnerHookMutationHookResult = ReturnType<typeof useUpdatePartnerHookMutation>;
export type UpdatePartnerHookMutationResult = Apollo.MutationResult<UpdatePartnerHookMutation>;
export type UpdatePartnerHookMutationOptions = Apollo.BaseMutationOptions<UpdatePartnerHookMutation, UpdatePartnerHookMutationVariables>;
export const ResetPracticeDocument = gql`
    mutation resetPractice($practice: ResetPracticeInput!) {
  resetPractice(practice: $practice) {
    success
  }
}
    `;
export type ResetPracticeMutationFn = Apollo.MutationFunction<ResetPracticeMutation, ResetPracticeMutationVariables>;

/**
 * __useResetPracticeMutation__
 *
 * To run a mutation, you first call `useResetPracticeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPracticeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPracticeMutation, { data, loading, error }] = useResetPracticeMutation({
 *   variables: {
 *      practice: // value for 'practice'
 *   },
 * });
 */
export function useResetPracticeMutation(baseOptions?: Apollo.MutationHookOptions<ResetPracticeMutation, ResetPracticeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPracticeMutation, ResetPracticeMutationVariables>(ResetPracticeDocument, options);
      }
export type ResetPracticeMutationHookResult = ReturnType<typeof useResetPracticeMutation>;
export type ResetPracticeMutationResult = Apollo.MutationResult<ResetPracticeMutation>;
export type ResetPracticeMutationOptions = Apollo.BaseMutationOptions<ResetPracticeMutation, ResetPracticeMutationVariables>;
export const MarkEntitiesAsFetchedDocument = gql`
    mutation markEntitiesAsFetched($markEntitiesAsFetched: MarkedAsFetchedInput!) {
  markEntitiesAsFetched(markEntitiesAsFetched: $markEntitiesAsFetched) {
    rowsAffected
  }
}
    `;
export type MarkEntitiesAsFetchedMutationFn = Apollo.MutationFunction<MarkEntitiesAsFetchedMutation, MarkEntitiesAsFetchedMutationVariables>;

/**
 * __useMarkEntitiesAsFetchedMutation__
 *
 * To run a mutation, you first call `useMarkEntitiesAsFetchedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkEntitiesAsFetchedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markEntitiesAsFetchedMutation, { data, loading, error }] = useMarkEntitiesAsFetchedMutation({
 *   variables: {
 *      markEntitiesAsFetched: // value for 'markEntitiesAsFetched'
 *   },
 * });
 */
export function useMarkEntitiesAsFetchedMutation(baseOptions?: Apollo.MutationHookOptions<MarkEntitiesAsFetchedMutation, MarkEntitiesAsFetchedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarkEntitiesAsFetchedMutation, MarkEntitiesAsFetchedMutationVariables>(MarkEntitiesAsFetchedDocument, options);
      }
export type MarkEntitiesAsFetchedMutationHookResult = ReturnType<typeof useMarkEntitiesAsFetchedMutation>;
export type MarkEntitiesAsFetchedMutationResult = Apollo.MutationResult<MarkEntitiesAsFetchedMutation>;
export type MarkEntitiesAsFetchedMutationOptions = Apollo.BaseMutationOptions<MarkEntitiesAsFetchedMutation, MarkEntitiesAsFetchedMutationVariables>;
export const PartnersDocument = gql`
    query partners {
  partners {
    id
    name
    isEnabled
    singleClientOwnership
    createdAt
  }
}
    `;

/**
 * __usePartnersQuery__
 *
 * To run a query within a React component, call `usePartnersQuery` and pass it any options that fit your needs.
 * When your component renders, `usePartnersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePartnersQuery({
 *   variables: {
 *   },
 * });
 */
export function usePartnersQuery(baseOptions?: Apollo.QueryHookOptions<PartnersQuery, PartnersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PartnersQuery, PartnersQueryVariables>(PartnersDocument, options);
      }
export function usePartnersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PartnersQuery, PartnersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PartnersQuery, PartnersQueryVariables>(PartnersDocument, options);
        }
export function usePartnersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<PartnersQuery, PartnersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PartnersQuery, PartnersQueryVariables>(PartnersDocument, options);
        }
export type PartnersQueryHookResult = ReturnType<typeof usePartnersQuery>;
export type PartnersLazyQueryHookResult = ReturnType<typeof usePartnersLazyQuery>;
export type PartnersSuspenseQueryHookResult = ReturnType<typeof usePartnersSuspenseQuery>;
export type PartnersQueryResult = Apollo.QueryResult<PartnersQuery, PartnersQueryVariables>;
export const PartnerDocument = gql`
    query partner($id: UUID!) {
  partner(id: $id) {
    id
    isEnabled
    singleClientOwnership
    name
    createdAt
    updatedAt
  }
}
    `;

/**
 * __usePartnerQuery__
 *
 * To run a query within a React component, call `usePartnerQuery` and pass it any options that fit your needs.
 * When your component renders, `usePartnerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePartnerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePartnerQuery(baseOptions: Apollo.QueryHookOptions<PartnerQuery, PartnerQueryVariables> & ({ variables: PartnerQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PartnerQuery, PartnerQueryVariables>(PartnerDocument, options);
      }
export function usePartnerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PartnerQuery, PartnerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PartnerQuery, PartnerQueryVariables>(PartnerDocument, options);
        }
export function usePartnerSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<PartnerQuery, PartnerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PartnerQuery, PartnerQueryVariables>(PartnerDocument, options);
        }
export type PartnerQueryHookResult = ReturnType<typeof usePartnerQuery>;
export type PartnerLazyQueryHookResult = ReturnType<typeof usePartnerLazyQuery>;
export type PartnerSuspenseQueryHookResult = ReturnType<typeof usePartnerSuspenseQuery>;
export type PartnerQueryResult = Apollo.QueryResult<PartnerQuery, PartnerQueryVariables>;
export const PracticesDocument = gql`
    query practices {
  practices {
    id
    name
    integration
    isActive
    createdAt
  }
}
    `;

/**
 * __usePracticesQuery__
 *
 * To run a query within a React component, call `usePracticesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePracticesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePracticesQuery({
 *   variables: {
 *   },
 * });
 */
export function usePracticesQuery(baseOptions?: Apollo.QueryHookOptions<PracticesQuery, PracticesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PracticesQuery, PracticesQueryVariables>(PracticesDocument, options);
      }
export function usePracticesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PracticesQuery, PracticesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PracticesQuery, PracticesQueryVariables>(PracticesDocument, options);
        }
export function usePracticesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<PracticesQuery, PracticesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PracticesQuery, PracticesQueryVariables>(PracticesDocument, options);
        }
export type PracticesQueryHookResult = ReturnType<typeof usePracticesQuery>;
export type PracticesLazyQueryHookResult = ReturnType<typeof usePracticesLazyQuery>;
export type PracticesSuspenseQueryHookResult = ReturnType<typeof usePracticesSuspenseQuery>;
export type PracticesQueryResult = Apollo.QueryResult<PracticesQuery, PracticesQueryVariables>;
export const PracticeDocument = gql`
    query practice($id: UUID!) {
  practice(id: $id) {
    id
    name
    colorCode
    contact
    currencyId
    email
    facebookUrl
    goLiveDate
    installation
    integration
    integrationId
    isActive
    isDeleted
    label
    languageId
    localeId
    migratedFrom
    serverId
    siteId
    websiteUrl
    timezoneId
    privateKey
    createdAt
    updatedAt
  }
}
    `;

/**
 * __usePracticeQuery__
 *
 * To run a query within a React component, call `usePracticeQuery` and pass it any options that fit your needs.
 * When your component renders, `usePracticeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePracticeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePracticeQuery(baseOptions: Apollo.QueryHookOptions<PracticeQuery, PracticeQueryVariables> & ({ variables: PracticeQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PracticeQuery, PracticeQueryVariables>(PracticeDocument, options);
      }
export function usePracticeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PracticeQuery, PracticeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PracticeQuery, PracticeQueryVariables>(PracticeDocument, options);
        }
export function usePracticeSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<PracticeQuery, PracticeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PracticeQuery, PracticeQueryVariables>(PracticeDocument, options);
        }
export type PracticeQueryHookResult = ReturnType<typeof usePracticeQuery>;
export type PracticeLazyQueryHookResult = ReturnType<typeof usePracticeLazyQuery>;
export type PracticeSuspenseQueryHookResult = ReturnType<typeof usePracticeSuspenseQuery>;
export type PracticeQueryResult = Apollo.QueryResult<PracticeQuery, PracticeQueryVariables>;
export const PartnerHooksDocument = gql`
    query partnerHooks {
  partnerHooks {
    id
    name
    partnerId
    practiceId
    parser
    sendAppointment
    sendAppointmentStatus
    sendAppointmentType
    sendBlockoff
    sendBreed
    sendClient
    sendClientPhoneNumber
    sendCurrency
    sendEmployee
    sendInvoice
    sendLanguage
    sendLocale
    sendPatientReminder
    sendPatient
    sendOwnership
    sendPractice
    sendResource
    sendSex
    sendSpecies
    sendTimezone
    headers {
      name
      value
    }
    isActive
    url
    createdAt
    updatedAt
  }
}
    `;

/**
 * __usePartnerHooksQuery__
 *
 * To run a query within a React component, call `usePartnerHooksQuery` and pass it any options that fit your needs.
 * When your component renders, `usePartnerHooksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePartnerHooksQuery({
 *   variables: {
 *   },
 * });
 */
export function usePartnerHooksQuery(baseOptions?: Apollo.QueryHookOptions<PartnerHooksQuery, PartnerHooksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PartnerHooksQuery, PartnerHooksQueryVariables>(PartnerHooksDocument, options);
      }
export function usePartnerHooksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PartnerHooksQuery, PartnerHooksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PartnerHooksQuery, PartnerHooksQueryVariables>(PartnerHooksDocument, options);
        }
export function usePartnerHooksSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<PartnerHooksQuery, PartnerHooksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PartnerHooksQuery, PartnerHooksQueryVariables>(PartnerHooksDocument, options);
        }
export type PartnerHooksQueryHookResult = ReturnType<typeof usePartnerHooksQuery>;
export type PartnerHooksLazyQueryHookResult = ReturnType<typeof usePartnerHooksLazyQuery>;
export type PartnerHooksSuspenseQueryHookResult = ReturnType<typeof usePartnerHooksSuspenseQuery>;
export type PartnerHooksQueryResult = Apollo.QueryResult<PartnerHooksQuery, PartnerHooksQueryVariables>;
export const PartnerHookDocument = gql`
    query partnerHook($id: UUID!) {
  partnerHook(id: $id) {
    id
    name
    partnerId
    practiceId
    parser
    sendAppointment
    sendAppointmentStatus
    sendAppointmentType
    sendBlockoff
    sendBreed
    sendClient
    sendClientPhoneNumber
    sendCurrency
    sendEmployee
    sendInvoice
    sendLanguage
    sendLocale
    sendPatientReminder
    sendPatient
    sendOwnership
    sendPractice
    sendResource
    sendSex
    sendSpecies
    sendTimezone
    headers {
      name
      value
    }
    isActive
    url
    createdAt
    updatedAt
  }
}
    `;

/**
 * __usePartnerHookQuery__
 *
 * To run a query within a React component, call `usePartnerHookQuery` and pass it any options that fit your needs.
 * When your component renders, `usePartnerHookQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePartnerHookQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePartnerHookQuery(baseOptions: Apollo.QueryHookOptions<PartnerHookQuery, PartnerHookQueryVariables> & ({ variables: PartnerHookQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PartnerHookQuery, PartnerHookQueryVariables>(PartnerHookDocument, options);
      }
export function usePartnerHookLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PartnerHookQuery, PartnerHookQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PartnerHookQuery, PartnerHookQueryVariables>(PartnerHookDocument, options);
        }
export function usePartnerHookSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<PartnerHookQuery, PartnerHookQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PartnerHookQuery, PartnerHookQueryVariables>(PartnerHookDocument, options);
        }
export type PartnerHookQueryHookResult = ReturnType<typeof usePartnerHookQuery>;
export type PartnerHookLazyQueryHookResult = ReturnType<typeof usePartnerHookLazyQuery>;
export type PartnerHookSuspenseQueryHookResult = ReturnType<typeof usePartnerHookSuspenseQuery>;
export type PartnerHookQueryResult = Apollo.QueryResult<PartnerHookQuery, PartnerHookQueryVariables>;
export const GetEntitiesDocument = gql`
    query getEntities {
  getEntities {
    name
    key
  }
}
    `;

/**
 * __useGetEntitiesQuery__
 *
 * To run a query within a React component, call `useGetEntitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEntitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEntitiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEntitiesQuery(baseOptions?: Apollo.QueryHookOptions<GetEntitiesQuery, GetEntitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEntitiesQuery, GetEntitiesQueryVariables>(GetEntitiesDocument, options);
      }
export function useGetEntitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEntitiesQuery, GetEntitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEntitiesQuery, GetEntitiesQueryVariables>(GetEntitiesDocument, options);
        }
export function useGetEntitiesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetEntitiesQuery, GetEntitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetEntitiesQuery, GetEntitiesQueryVariables>(GetEntitiesDocument, options);
        }
export type GetEntitiesQueryHookResult = ReturnType<typeof useGetEntitiesQuery>;
export type GetEntitiesLazyQueryHookResult = ReturnType<typeof useGetEntitiesLazyQuery>;
export type GetEntitiesSuspenseQueryHookResult = ReturnType<typeof useGetEntitiesSuspenseQuery>;
export type GetEntitiesQueryResult = Apollo.QueryResult<GetEntitiesQuery, GetEntitiesQueryVariables>;