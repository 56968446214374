import { useMutation, useQuery } from "@apollo/client";
import { Box, Button, FormControl, FormControlLabel,  MenuItem, Select, Switch, TextField, Typography } from "@mui/material";
import { useForm } from "react-hook-form"
import { useEffect, useState } from "react";
import { getValue } from "@testing-library/user-event/dist/utils";
import { useCreatePartnerHookMutation, usePracticesQuery } from "../../apiClient/generated/graphql";

interface PartnerHookFormInputs {
    name: string
    practiceId: string
    url: string

    sendAppointment: boolean,
    sendAppointmentStatus: boolean,
    sendAppointmentType: boolean,

    sendBlockoff: boolean,
    sendBreed: boolean,
    sendClient: boolean,
    sendClientPhoneNumber: boolean,
    sendCurrency: boolean,
    sendEmployee: boolean,
    sendInvoice: boolean,
    sendLanguage: boolean,
    sendLocale: boolean,
    sendPatientReminder: boolean,
    sendPatient: boolean,
    sendOwnership: boolean,
    sendPractice: boolean,
    sendResource: boolean,
    sendSex: boolean,
    sendSpecies: boolean,
    sendTimezone: boolean,

    isActive: boolean,

    headers: {
        name: string
        value: string
    }[]
}

interface PartnerHookCreateProps {
    onComplete?: Function
}
const CreatePartnerHook = (props: PartnerHookCreateProps)=>{
    const [state, setState] = useState<{headers: PartnerHookFormInputs["headers"]}>({
        headers: []
    })
    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        reset,
    } = useForm<PartnerHookFormInputs>()

    const [createPartnerHook, { loading, error }] = useCreatePartnerHookMutation();
    
    
    const practicesQuery = usePracticesQuery()

    
    useEffect(()=>{
        reset({
            practiceId: practicesQuery.data?.practices?.[0]?.id ?? '',
        })
        setState({...state})
    }, [practicesQuery.data?.practices?.[0]?.id])
    
    
    const handleCreatePartnerHook = (partnerHook: any)=>{
        createPartnerHook({
            variables: {
                partnerHook: {
                    ...partnerHook,
                    parser: ""
                }
            }
        }).then(()=>{
            if(props.onComplete) props.onComplete()
        }).catch((error)=>{
            console.log(error)
        })
    }
    const handleAddHeader = ()=>{
        setState({
            headers: [
                ...(state?.headers ?? []),
                {
                    name: "",
                    value: ""
                }
            ]
        })
    }

    const handleSwitchChange = (key: keyof PartnerHookFormInputs, e: React.ChangeEvent<HTMLInputElement>)=>{
        setValue(key, !!e.target.checked)
        setState({...state})
    }

    const controllerStyle = {width: '100%'}
    return (
        <Box
            sx={{p: 2}}
            style={{minWidth: 400}}
        >
            <form onSubmit={handleSubmit(handleCreatePartnerHook)}>
                {/* register your input into the hook by invoking the "register" function */}
                {loading ? (<>
                    Loading...
                </>):(
                    <>
                        {error && error?.message}
                        <FormControl>
                            <Typography component="h4">
                                New PartnerHook
                            </Typography>
                        </FormControl>
                        <br />
                        <br />
                        <FormControl style={controllerStyle}>
                            <TextField id="name" defaultValue="" {...register("name")} required={true} variant='filled' label="Partner Hook Name" />
                        </FormControl>
                        <br />
                        <br />
                        Partner Level Hooks:
                        <br />
                        <FormControlLabel control={<Switch onChange={handleSwitchChange.bind(null, 'sendPractice')} defaultChecked={false} />} label='Send Practices'  />

                        {/* <FormControl>
                            <InputLabel htmlFor="partnerId">Partner Id</InputLabel>
                            <Input id="name" defaultValue="" {...register("name")} required={true} />
                        </FormControl> */}
                        
                        {!getValues().sendPractice && (
                            <>
                                <br />
                                <br />
                                Practice:
                                <FormControl style={controllerStyle}>
                                    <Select 
                                        id="practiceId" 
                                        {...register("practiceId")} 
                                        value={getValues().practiceId && practicesQuery.data?.practices?.[0]?.id}
                                        variant="filled" 
                                        label='Practice'>
                                        {practicesQuery.data?.practices?.map((practice)=>(
                                            <MenuItem value={practice?.id}>{practice?.name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </>
                        )}

                        {!getValues().sendPractice && (
                            <>
                                <br />
                                <br />

                                Practice Level Hooks
                                <br />
                                <FormControlLabel control={<Switch onChange={handleSwitchChange.bind(null, 'sendBreed')} defaultChecked={false} />} label='Send Breeds'  />
                                <br />
                                <FormControlLabel control={<Switch onChange={handleSwitchChange.bind(null, 'sendSex')} defaultChecked={false} />} label='Send Sex'  />
                                <br />
                                <FormControlLabel control={<Switch onChange={handleSwitchChange.bind(null, 'sendSpecies')} defaultChecked={false} />} label='Send Species'  />
                                <br />
                                <FormControlLabel control={<Switch onChange={handleSwitchChange.bind(null, 'sendTimezone')} defaultChecked={false} />} label='Send Timezones'  />
                                <br />
                                <FormControlLabel control={<Switch onChange={handleSwitchChange.bind(null, 'sendBlockoff')} defaultChecked={false} />} label='Send Blockoffs'  />
                                <br />
                                <FormControlLabel control={<Switch onChange={handleSwitchChange.bind(null, 'sendResource')} defaultChecked={false} />} label='Send Resources'  />
                                <br />
                                <FormControlLabel control={<Switch onChange={handleSwitchChange.bind(null, 'sendEmployee')} defaultChecked={false} />} label='Send Employees'  />
                                <br />
                                <FormControlLabel control={<Switch onChange={handleSwitchChange.bind(null, 'sendCurrency')} defaultChecked={false} />} label='Send Currencies'  />
                                <br />
                                <FormControlLabel control={<Switch onChange={handleSwitchChange.bind(null, 'sendLanguage')} defaultChecked={false} />} label='Send Languages'  />
                                <br />
                                <FormControlLabel control={<Switch onChange={handleSwitchChange.bind(null, 'sendLocale')} defaultChecked={false} />} label='Send Locales'  />
                                <br />
                                <FormControlLabel control={<Switch onChange={handleSwitchChange.bind(null, 'sendAppointmentType')} defaultChecked={false} />} label='Send Appointment Types'  />
                                <br />
                                <FormControlLabel control={<Switch onChange={handleSwitchChange.bind(null, 'sendAppointmentStatus')} defaultChecked={false} />} label='Send Appointment Statuses'  />
                                <br />
                                <br />

                                <FormControlLabel control={<Switch onChange={handleSwitchChange.bind(null, 'sendClient')} defaultChecked={false} />} label='Send Clients'  />
                                <br />
                                <FormControlLabel control={<Switch onChange={handleSwitchChange.bind(null, 'sendClientPhoneNumber')} defaultChecked={false} />} label='Send Client Phone Numbers'  />
                                <br />
                                <FormControlLabel control={<Switch onChange={handleSwitchChange.bind(null, 'sendPatient')} defaultChecked={false} />} label='Send Patients'  />
                                <br />
                                <FormControlLabel control={<Switch onChange={handleSwitchChange.bind(null, 'sendOwnership')} defaultChecked={false} />} label='Send Ownerships'  />
                                <br />
                                <FormControlLabel control={<Switch onChange={handleSwitchChange.bind(null, 'sendAppointment')} defaultChecked={false} />} label='Send Appointments'  />
                                <br />
                                <FormControlLabel control={<Switch onChange={handleSwitchChange.bind(null, 'sendBlockoff')} defaultChecked={false} />} label='Send Blockoffs'  />
                                <br />
                                <br />
                                <FormControlLabel control={<Switch onChange={handleSwitchChange.bind(null, 'sendPatientReminder')} defaultChecked={false} />} label='Send Patient Reminders'  />
                                <br />
                                <FormControlLabel control={<Switch onChange={handleSwitchChange.bind(null, 'sendInvoice')} defaultChecked={false} />} label='Send Invoices'  />
                            </>
                        )}
                        <>
                            <br />
                            <br />
                            Headers:
                            {state?.headers?.map((header, i)=>(
                                <div style={{margin: '0px -16px'}}>
                                    <FormControl sx={{p: 2}}>
                                        <TextField id={`headers.${i}.name`} defaultValue="" {...register(`headers.${i}.name`)} required={true} label='Name' variant='filled' />
                                    </FormControl>
                                    <FormControl sx={{p: 2}}>
                                        <TextField id={`headers.${i}.value`} defaultValue="" {...register(`headers.${i}.value`)} required={true}  label='Value' variant='filled' />
                                    </FormControl>
                                </div>
                            ))}
                        </>
                        <br />
                        <Button onClick={handleAddHeader} variant="contained">Add Header</Button>

                        <br />
                        <br />
                        <FormControl style={controllerStyle} >
                            <TextField id={`url`} defaultValue="" {...register(`url`)} required={true} label='Url' variant='filled' />
                        </FormControl>
                        <br />

                        <FormControlLabel control={<Switch onChange={handleSwitchChange.bind(null, 'isActive')} defaultChecked={false} />} label='Active'  />

                        {/* include validation with required or other standard HTML validation rules */}
                        {/* errors will return when field validation fails  */}
                        <br />
                        <Button type="submit" variant="contained">Create</Button>
                    </>
                )}
            </form>
        </Box>
    )
}

export default CreatePartnerHook