import useSession from "../helpers/useSessionState";
import { Button, Drawer, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { useState } from "react";
import CreatePartnerHook from "../components/forms/PartnerHookCreate";
import { Link } from "react-router-dom";
import Layout from "../components/Layout";
import { usePartnerHooksQuery } from "../apiClient/generated/graphql";

const PartnerHooks = ()=>{
    const [state, setState] = useState({
        drawerOpen: false
    })

    const { loading, error, data, refetch } = usePartnerHooksQuery();

    const [sessionState] = useSession()
    const setDrawer = (value: boolean)=>{
        setState({
            ...state,
            drawerOpen: value
        })
        setTimeout(()=>{
            refetch()
        }, 5000)
    }
    
    
    // const handleSelectPartnerHook = (partner: ApiClient.GetPartnerHooks["partnerHooks"][0])=>{
    //     setSession({
    //         ...Object(sessionState),
    //         currentPartnerHookId: partner.id
    //     })
    //     navigate("/")
    // }

    return <Layout page="PartnerHooks">
        {loading ? (
            <>
                LOADING...
            </>
        ):error?(
            <>{error.message}</>
        ):(
            <>
                {sessionState?.isSuper && (
                    <div>
                        <Button variant="contained" onClick={setDrawer.bind(null, true)} style={{float: 'right'}}>Create PartnerHook</Button>
                        <Drawer
                            anchor='right'
                            open={state.drawerOpen}
                            onClose={setDrawer.bind(null, false)}
                        >
                            <CreatePartnerHook onComplete={setDrawer.bind(null, false)}/>
                        </Drawer>
                    </div>
                )}
                

                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Active</TableCell>
                            <TableCell>Is Active</TableCell>
                            <TableCell>Created At</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data?.partnerHooks?.map((partnerHook, i: Number)=>(
                            <TableRow
                                key={partnerHook?.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell>
                                    <Link to={`/web-hooks/${partnerHook?.id}`}>{partnerHook?.name}</Link>
                                </TableCell>
                                <TableCell>{partnerHook?.createdAt}</TableCell>
                                <TableCell>{JSON.stringify(partnerHook?.isActive)}</TableCell>
                                <TableCell>{partnerHook?.createdAt}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                
            </>
        )}
    </Layout>
}

export default PartnerHooks