import useSession from "../helpers/useSessionState";
import { Button, Drawer, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { useState } from "react";
import CreatePartner from "../components/forms/PartnerCreate";
import { Link, useNavigate } from "react-router-dom";
import Layout from "../components/Layout";
import { PartnersQuery, usePartnersQuery } from "../apiClient/generated/graphql";

type Partner = NonNullable<NonNullable<PartnersQuery["partners"]>[number]>;

const Partners = ()=>{
    const [state, setState] = useState({
        drawerOpen: false
    })

    const navigate = useNavigate()
    const { loading, error, data, refetch } = usePartnersQuery();

    // useQuery<ApiClient.GetPartners>(apiClient.queries.GET_PARTNERS);

    const [sessionState, setSession] = useSession()
    const setDrawer = (value: boolean)=>{
        refetch()
        setState({
            ...state,
            drawerOpen: value
        })
    }
                
    
    const handleSelectPartner = (partner: Partner)=>{
        setSession({
            ...Object(sessionState),
            currentPartnerId: partner.id
        })
        console.log(partner.id)
        navigate("/")
    }
    
    

    return <Layout page="Partners">
        {loading ? (
            <>
                LOADING...
            </>
        ):error?(
            <>{error.message}</>
        ):(
            <>
                {sessionState?.isSuper && (
                    <div>
                        <Button variant="contained" onClick={setDrawer.bind(null, true)} style={{float: 'right'}}>Create Partner</Button>
                        <Drawer
                            anchor='right'
                            open={state.drawerOpen}
                            onClose={setDrawer.bind(null, false)}
                        >
                            <CreatePartner onComplete={setDrawer.bind(null, false)}/>
                        </Drawer>
                    </div>
                )}
                

                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Is Single Ownership</TableCell>
                            {sessionState?.isSuper && (
                                <TableCell>Actions</TableCell>
                            )}
                            <TableCell>Created At</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data?.partners?.map((partner, i: Number) => (
                            <TableRow
                                key={partner?.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    <Link to={`/partners/${partner?.id}`} >{partner?.name}</Link>
                                </TableCell>
                                <TableCell align="right">{partner?.singleClientOwnership}</TableCell>
                                
                                {sessionState?.isSuper && (
                                    <TableCell align="center">
                                        <Button onClick={handleSelectPartner.bind(null, partner as Partner)} variant="contained">Use Current</Button>
                                    </TableCell>
                                )}

                                <TableCell align="right">{partner?.createdAt}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </>
        )}
    </Layout>
}

export default Partners