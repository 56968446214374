import { Box, Button, FormControl, FormControlLabel, Input, InputLabel, MenuItem, Select, Switch, Typography } from "@mui/material";
import { useForm } from "react-hook-form"
import { useEffect } from "react";
import { usePracticeQuery, useUpdatePracticeMutation } from "../../apiClient/generated/graphql";

interface PracticeFormInputs {
    name: string
    integration: string
    installation: string
    siteId: number
    isActive: boolean
}

interface PracticeUpdateProps {
    practiceId: string,
    onComplete?: Function
}
const UpdatePractice = (props: PracticeUpdateProps)=>{
    const {
        register,
        handleSubmit,
        setValue,
        reset
    } = useForm<PracticeFormInputs>()

        
    const [updatePractice] = useUpdatePracticeMutation()
    
    const { data, loading, error, refetch } = usePracticeQuery({
        variables: {
            id: props.practiceId
        }
    });

    const practice = data?.practice
    useEffect(()=>{
        reset({
            name: practice?.name ?? '',
            integration: practice?.integration ?? '',
            installation: practice?.installation ?? '',
            siteId: practice?.siteId ?? undefined,
            isActive: practice?.isActive ?? false,
        })
    }, [data?.practice])

    const handleUpdatePractice = (practiceParams: any)=>{
        if(practice?.id){
            updatePractice({
                variables: {
                    practice: {
                        ...practiceParams,
                        siteId: parseInt(practiceParams.siteId),
                        id: practice.id,
                    }
                }
            }).then(()=>{
                if(props.onComplete) props.onComplete()
            }).catch((error)=>{
                console.log(error)
            })
        }
    }

    const handleActiveChange = (e: React.ChangeEvent<HTMLInputElement>)=>{
        setValue("isActive", !!e.target.checked)
    }

    return (
        <Box
            sx={{p: 2}}
        >
            <form onSubmit={handleSubmit(handleUpdatePractice)}>
                {/* register your input into the hook by invoking the "register" function */}
                {loading ?(<>
                    Loading...
                </>):(
                    <>
                        {error && error?.message}
                        <FormControl>
                            <Typography component="h4">
                                Update Practice
                            </Typography>
                        </FormControl>
                        <br />
                        <br />
                        <FormControl>
                            <InputLabel htmlFor="name">Practice Name</InputLabel>
                            <Input id="name" defaultValue="" {...register("name")} required={true} />
                        </FormControl>
                        <br />
                        <FormControl>
                            <InputLabel htmlFor="installation">Installation</InputLabel>
                            <Input id="installation" defaultValue="" {...register("installation")} />
                        </FormControl>
                        <br />
                        <FormControl>
                            <InputLabel htmlFor="siteId">Site Id</InputLabel>
                            <Input id="siteId" defaultValue="" {...register("siteId")}  />
                        </FormControl>
                        <br />
                        {/* <FormControl>
                            <InputLabel htmlFor="integration">Integration</InputLabel>
                            <Input id="integration" defaultValue="" required={true} />
                        </FormControl> */}

                        <Select
                            labelId="integration"
                            id="integration"
                            label="Integration"
                            defaultValue={data?.practice?.integration}
                            {...register("integration")} 
                        >
                            <MenuItem value={'Covetrus'}>{'Covetrus'}</MenuItem>
                            <MenuItem value={'Datapoint'}>{'Datapoint'}</MenuItem>
                        </Select>
                        <br />
                        <FormControlLabel control={<Switch onChange={handleActiveChange} defaultChecked={!!data?.practice?.isActive} />} label='Active' /> 

                        {/* include validation with required or other standard HTML validation rules */}
                        {/* errors will return when field validation fails  */}
                        <br />
                        <Button type="submit" variant="contained">Update</Button>
                    </>
                )}
            </form>
        </Box>
    )
}

export default UpdatePractice